import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogType, Spinner, Toggle } from '@netspresso/components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import { Action } from '../../../../constants';
import { useAuthContext, useModalContext } from '../../../../contexts';
import { UserService } from '../../../../library/apis/UserService';

const DeleteUserDefaultValues = {
  currentPassword: '',
};

// todo: 다른파일로이동
const DeleteUserSchema = z.object({
  currentPassword: z.string(),
});

type DeleteUserType = z.infer<typeof DeleteUserSchema>;

export const DeleteAccount = () => {
  const navigate = useNavigate();

  const { signOut } = useAuthContext();

  const [, dispatchModal] = useModalContext();

  const { register, handleSubmit, getValues } = useForm({
    resolver: zodResolver(DeleteUserSchema),
    defaultValues: DeleteUserDefaultValues,
  });

  const handleClickConfirmDeletedSuccess = () => {
    dispatchModal({ type: Action.HIDE });
    signOut();
    navigate('/');
  };

  const handleDeleteUserSuccess = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Delete account"
          infoText="Your account has been deleted."
          width="w-[600px]"
          onClickConfirm={handleClickConfirmDeletedSuccess}
        />
      ),
    });
  };

  const handleClickConfirmDeletedError = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const handleUpdateUserError = (_: AxiosError) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Delete account"
          infoText="Please check your current password."
          width="w-[600px]"
          onClickConfirm={handleClickConfirmDeletedError}
        />
      ),
    });
  };

  const { mutate, isLoading } = useMutation(UserService.deleteUser, {
    onSuccess: handleDeleteUserSuccess,
    onError: handleUpdateUserError,
  });

  const handleClickConfirmDelete = () => {
    mutate({
      password: getValues('currentPassword'),
    });
  };

  const handleClickCancelDelete = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const handleClickDeleteUser: SubmitHandler<DeleteUserType> = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.confirm}
          title="Are you sure you want to delete?"
          infoText="Your account cannot be recovered."
          width="w-[600px]"
          onClickConfirm={handleClickConfirmDelete}
          onClickCancel={handleClickCancelDelete}
          confirmDisabled={isLoading}
        />
      ),
    });
  };

  const handleInvalidInputs: SubmitErrorHandler<DeleteUserType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const [isDeletable, setIsDeletable] = useState(false);

  const handleToggleDeletable = () => {
    setIsDeletable(!isDeletable);
  };

  return (
    <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">
        <div className="flex flex-row items-center gap-4">
          Delete account
          <Toggle value={isDeletable} onClick={handleToggleDeletable} />
        </div>
      </h1>
      <section className="bg-white rounded-lg shadow p-6">
        <form onSubmit={handleSubmit(handleClickDeleteUser, handleInvalidInputs)}>
          <p className="mb-4">If you delete your account, your datasets, projects, models will be deleted forever.</p>
          <hr className="mb-4" />
          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="current-password">
              Current password *
            </label>
            <input
              {...register('currentPassword')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="password"
              id="current-password"
              autoComplete="current-password"
              placeholder="Please insert your current password"
              disabled={!isDeletable || isLoading}
            />
          </section>
          <section className="flex gap-4">
            <Button variant="outline" disabled={!isDeletable || isLoading} color="danger" type="submit">
              Delete my account
            </Button>
            {isLoading && <Spinner />}
          </section>
        </form>
      </section>
    </div>
  );
};
