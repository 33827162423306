import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { REACT_QUERY } from '../../../../constants';
import { Notice, NoticeService } from '../../../../library/apis';
import { NoticeControl } from '../../../../library/utils';

export const NoticeBar = () => {
  const [show, setShow] = useState(false);

  const [notice, setNotice] = useState<Notice>();

  useQuery([REACT_QUERY.RECENT_NOTICE], NoticeService.readRecentNotice, {
    onSuccess: ({ data: notice }) => {
      // 사용자가 삭제하지 않았고, 시간이 현재 유효한 공지인 경우만 공개
      if (
        !NoticeControl.isBanned(notice.notice_id) &&
        NoticeControl.isValidTime(notice.announce_start_time, notice.announce_expiry_time)
      ) {
        setNotice(notice);
        setShow(true);
      }
    },
  });

  const handleClickClose = () => {
    setShow(false);
    if (notice) {
      NoticeControl.banForHours(notice.notice_id, notice.cookie_lifetime);
    }
  };

  if (!show) return <></>;

  return (
    <section className="w-full bg-black">
      <div className="max-w-7xl h-12 mx-auto px-8 flex items-center gap-4">
        <div className="flex-1 flex justify-center items-center whitespace-nowrap overflow-hidden text-ellipsis text-white">
          {notice?.link_url && (
            <a href={notice?.link_url} className="hover:underline" target="_blank" rel="noreferrer">
              {notice?.content}
            </a>
          )}
          {!notice?.link_url && notice?.content}
        </div>
        <div className="flex justify-center items-center">
          <button onClick={handleClickClose} className="material-icons text-white">
            close
          </button>
        </div>
      </div>
    </section>
  );
};
