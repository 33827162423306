export const INVALID_EMAIL = 'This email address is invalid.';
export const TOO_SHORT_FIRST_NAME = 'Please enter your first name with at least two characters.';
export const TOO_LONG_FIRST_NAME = 'Please enter your first name with at most 40 characters.';
export const ALPHABET_ONLY_FIRST_NAME = 'Please enter your first name in alphabets only.';
export const TOO_SHORT_LAST_NAME = 'Please enter your last name with at least two characters.';
export const TOO_LONG_LAST_NAME = 'Please enter your last name with at most 40 characters.';
export const ALPHABET_ONLY_LAST_NAME = 'Please enter your company in alphabets only.';
export const TOO_SHORT_COMPANY = 'Please enter your company with at least two characters.';
export const TOO_LONG_COMPANY = 'Please enter your company with at most 40 characters.';
export const ALPHABET_NUMBER_ONLY_COMPANY = 'Please enter your company in alphabets and numbers only.';
export const TOO_SHORT_PASSWORD = 'Please enter your password with at least 8 characters.';
export const INVALID_PASSWORD =
  'The password format is incorrect.\nPlease include Uppercase letters, special characters(!@#$%^&*) and numbers.';
export const NEED_PASSWORD = 'Please enter your password to change your information.';
export const AGE_AGREE = 'Please agree that you are over 14 years old.';
export const PERSONAL_AGREE = 'Please agree to the personal information agreement.';
export const PRIVACY_AGREE = 'Please agree to the privacy policy.';
export const TERMS_AGREE = 'Please agree to the terms and conditions.';
export const NOT_MATCHED_PASSWORD = 'Password and confirm password do not match.';

// notice
export const NEED_NOTICE_TITLE = 'Please enter a notice title to identify each other.';
export const TOO_LONG_NOTICE_TITLE = 'Please enter a notice title with at most 30 characters.';
export const NEED_NOTICE_CONTENT_TITLE = 'Please enter a notice content to show.';
export const TOO_LONG_NOTICE_CONTENT_TITLE = 'Please enter a notice title with at most 200 characters.';
export const INVALID_NOTICE_LINK_URL =
  'Link URL is incorrect.\nPlease enter the following format: https://portal.netspresso.ai';
export const INVALID_NOTICE_ANNOUNCE_START_TIME =
  'Start time is incorrect.\nPlease enter the following format: yyyy-MM-dd hh:mm:ss';
export const INVALID_NOTICE_ANNOUNCE_EXPIRY_TIME =
  'End time is incorrect.\nPlease enter the following format: yyyy-MM-dd hh:mm:ss';
export const INVALID_NOTICE_ANNOUNCE_TIME = 'End time should be later than start time.';
export const NEED_NOTICE_COOKIE_LIFE_TIME = 'Please enter a cookie life time.';
export const INVALID_NOTICE_COOKIE_LIFE_TIME = 'Please enter a cookie life time with a number.';
export const MINIMUM_NOTICE_COOKIE_LIFE_TIME = 'Cookie life time should be at least 1.';
export const MAXIMUM_NOTICE_COOKIE_LIFE_TIME = 'Cookie life time should be at most 999.';
export const INVALID_RELEASE_VERSION = 'Release version is incorrect.\nPlease enter the following format: 0.0.1';

export const WRONG_CHARACTERS = 'Your input contains not allowed characters.';
