export const enUs = {
  SIGN_IN: {
    TITLE: `Sign in to your account`,
    EMAIL_INPUT: `Email address`,
    PASSWORD_INPUT: `Password`,
    FORGET_PASSWORD: `Forget your password?`,
    SIGN_IN_BUTTON: `Sign in`,
    SIGN_UP: `Don't have an account?`,
    SIGN_UP_LINK: `Sign Up`,
    SIGN_IN_SUCCESS_TITLE: `Sign in successed`,
    SIGN_IN_SUCCESS_CONTENT: `Sign in successed.`,
    SIGN_IN_SUCCESS_CONFIRM_BUTTON: `Confirm`,
    SIGN_IN_SUCCESS_CANCEL_BUTTON: `Cancel`,
    SIGN_IN_FAILURE_TITLE: `Sign in failed`,
    SIGN_IN_FAILURE_CONTENT_0: `Server is not reply.\nPlease check the network or server status.`,
    SIGN_IN_FAILURE_CONTENT_400: `We didn't recognize the email address or password you entered.\nPlease try again.`,
    SIGN_IN_FAILURE_CONTENT_403: `Your email has not yet been validated.\nDo you want to resend the validation email?`,
    SIGN_IN_FAILURE_CONTENT_500: `Server error. Please try again later.`,
    SIGN_IN_FAILURE_CONFIRM_BUTTON: `Confirm`,
    SIGN_IN_FAILURE_CANCEL_BUTTON: `Cancel`,
    SEND_ACTIVATION_EMAIL_SUCCESS_TITLE: `Send activation email successed`,
    SEND_ACTIVATION_EMAIL_SUCCESS_CONTENT: `Validation email has been sent.`,
    SEND_ACTIVATION_EMAIL_SUCCESS_CONFIRM_BUTTON: `Confirm`,
    SEND_ACTIVATION_EMAIL_FAILURE_TITLE: `Send activation email failed`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_0: `Server is not reply.\nPlease check the network or server status.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_400: `Invalid email address.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_500: `Server error. Please try again later.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONFIRM_BUTTON: `Confirm`,
  },
  SIGN_UP: {
    TITLE: `Create a new account`,
    EMAIL_INPUT: `Email address`,
    FIRST_NAME_INPUT: `First name`,
    LAST_NAME_INPUT: `Last name`,
    COMPANY_INPUT: `Company`,
    PASSWORD_INPUT: `Password`,
    CONFIRM_PASSWORD_INPUT: `Confirm password`,
    REGION_INPUT: `Access region`,
    REGION_US: `United States`,
    REGION_EU: `Europe`,
    REGION_ASIA: `Asia`,
    AGE_AGREE_INPUT: `<b>I am 14 years or older to access this service</b>`,
    PERSONAL_AGREE_INPUT: `I accept the <b>Personal Information Agreement</b>`,
    PRIVACY_POLICY_INPUT: `I accept the <b>Privacy Policy</b>`,
    TERMS_AGREE: `I accept the <b>Terms of Service</b>`,
    MARKETING_AGREE: `I accept to receive communications or be contacted by Nota about related technology`,
    SIGN_UP_BUTTON: `Create`,
    SIGN_IN: `Already have an account?`,
    SIGN_IN_LINK: `Sign in`,
    SIGN_UP_SUCCESS_TITLE: `Sign up success`,
    SIGN_UP_SUCCESS_CONTENT: `An email has been sent to <b>{{email}}</b> \nIf you haven't received the activation email, please check your spam folder.\n\nFor further assistance, contact us at netspresso@nota.ai`,
    SIGN_UP_SUCCESS_CONFIRM: `Confirm`,
    SIGN_UP_FAILURE_TITLE: `Sign up failed`,
    SIGN_UP_FAILURE_CONTENT_403: `This email is already exists.`,
    SIGN_UP_FAILURE_CONFIRM: `Confirm`,
    INPUT_VALIDATION_TITLE: `Input error`,
    INPUT_VALIDATION_CONFIRM: `Confirm`,
    INPUT_VALIDATION_INVALID_EMAIL: 'This email address is invalid.',
    INPUT_VALIDATION_TOO_SHORT_FIRST_NAME: 'Please enter your first name with at least 2 characters.',
    INPUT_VALIDATION_TOO_LONG_FIRST_NAME: 'Please enter your first name with at most 40 characters.',
    INPUT_VALIDATION_ALPHABET_ONLY_FIRST_NAME: 'Please enter your first name in alphabets only.',
    INPUT_VALIDATION_TOO_SHORT_LAST_NAME: 'Please enter your last name with at least 2 characters.',
    INPUT_VALIDATION_TOO_LONG_LAST_NAME: 'Please enter your last name with at most 40 characters.',
    INPUT_VALIDATION_ALPHABET_ONLY_LAST_NAME: 'Please enter your last name in alphabets only.',
    INPUT_VALIDATION_TOO_SHORT_COMPANY: 'Please enter your company with at least 2 characters.',
    INPUT_VALIDATION_TOO_LONG_COMPANY: 'Please enter your company with at most 40 characters.',
    INPUT_VALIDATION_ALPHABET_NUMBER_ONLY_COMPANY: 'Please enter your company in alphabets and numbers only.',
    INPUT_VALIDATION_INVALID_PASSWORD:
      'The password format is incorrect.\nPlease include Uppercase letters, special characters(!@#$%^&*) and numbers.',
    INPUT_VALIDATION_TOO_SHORT_PASSWORD: 'Please enter your password with at least 8 characters.',
    INPUT_VALIDATION_AGE_AGREE: 'Please agree that you are over 14 years old.',
    INPUT_VALIDATION_PERSONAL_AGREE: 'Please agree to the personal information agreement.',
    INPUT_VALIDATION_PRIVACY_AGREE: 'Please agree to the privacy policy.',
    INPUT_VALIDATION_TERMS_AGREE: 'Please agree to the terms and conditions.',
    INPUT_VALIDATION_NOT_MATCHED_PASSWORD: 'Password and confirm password do not match.',
    ACTIVATE_FAIL: 'Fail to activate your account?',
    CONTACT_US: 'Contact us',
  },
  PERSONAL_AGREE: {
    TITLE: 'Personal Information Agreement',
    AGREE_BUTTON: 'I accept the Personal Information Agreement',
    DISAGREE_BUTTON: 'Disagree',
    CLOSE_BUTTON: 'Close',
  },
  PRIVACY_AGREE: {
    TITLE: 'Privacy Policy',
    AGREE_BUTTON: 'I accept the Privacy Agreement',
    DISAGREE_BUTTON: 'Disagree',
    CLOSE_BUTTON: 'Close',
  },
  TERMS_AGREE: {
    TITLE: 'Terms of Service',
    AGREE_BUTTON: 'I accept the Terms of Service',
    DISAGREE_BUTTON: 'Disagree',
    CLOSE_BUTTON: 'Close',
  },
};
