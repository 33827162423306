import axios from 'axios';
import { TokenService } from '../../services';

export const apiClient = axios.create();

apiClient.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (config.headers === undefined) {
      config.headers = {};
    }

    if (token) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
