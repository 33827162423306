import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogType, Spinner } from '@netspresso/components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Action } from '../../../../constants';
import {
  INVALID_PASSWORD,
  NEED_PASSWORD,
  NOT_MATCHED_PASSWORD,
  TOO_SHORT_PASSWORD,
} from '../../../../constants/errors';
import { useModalContext } from '../../../../contexts';
import { User, UserService } from '../../../../library/apis/UserService';

const UpdatePasswordDefaultValues = {
  newPassword: '',
  confirmPassword: '',
  currentPassword: '',
};

// todo: 다른파일로이동
const UpdatePasswordSchema = z
  .object({
    newPassword: z
      .string()
      .regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{1,}$/, INVALID_PASSWORD)
      .min(8, TOO_SHORT_PASSWORD),
    confirmPassword: z.string(),
    currentPassword: z.string().min(1, NEED_PASSWORD),
  })
  .refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
    message: NOT_MATCHED_PASSWORD,
    path: ['confirmPassword'],
  });

type UpdatePasswordType = z.infer<typeof UpdatePasswordSchema>;

export const UpdatePassword = () => {
  const [, dispatchModal] = useModalContext();

  const { register, handleSubmit, reset } = useForm({
    resolver: zodResolver(UpdatePasswordSchema),
    defaultValues: UpdatePasswordDefaultValues,
  });

  const handleUpdatePasswordSuccess = (_: AxiosResponse<User>) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Change password"
          infoText="Your changes have been saved."
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
    reset();
  };

  const handleUpdatePasswordError = (_: AxiosError) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Change password"
          infoText="Please check your current password."
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
  };

  const { mutate, isLoading } = useMutation(UserService.updatePassword, {
    onSuccess: handleUpdatePasswordSuccess,
    onError: handleUpdatePasswordError,
  });

  const handleClickUpdatePassword: SubmitHandler<UpdatePasswordType> = ({ newPassword, currentPassword }) => {
    mutate({ newPassword, currentPassword });
  };

  const handleInvalidInputs: SubmitErrorHandler<UpdatePasswordType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickConfirm = () => {
    dispatchModal({ type: Action.HIDE });
  };

  return (
    <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">Change password</h1>
      <section className="bg-white rounded-lg shadow p-6">
        <form className="w-full" onSubmit={handleSubmit(handleClickUpdatePassword, handleInvalidInputs)}>
          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="new-password">
              New password *
            </label>
            <input
              {...register('newPassword')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="password"
              id="new-password"
              autoComplete="new-password"
              placeholder="Please insert a new password"
              disabled={isLoading}
            />
          </section>
          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="confirm-password">
              Confirm password *
            </label>
            <input
              {...register('confirmPassword')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="password"
              id="confirm-password"
              autoComplete="confirm-password"
              placeholder="Please insert a new password again"
              disabled={isLoading}
            />
          </section>
          <hr className="mb-4" />
          <section className="mb-6">
            <label
              className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
              htmlFor="update-password-current-password"
            >
              Current password *
            </label>
            <input
              {...register('currentPassword')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="password"
              id="update-password-current-password"
              autoComplete="current-password"
              placeholder="Please insert your current password"
              disabled={isLoading}
            />
          </section>
          <section className="flex gap-4">
            <Button color="secondary" type="submit" disabled={isLoading}>
              Save
            </Button>
            {isLoading && <Spinner />}
          </section>
        </form>
      </section>
    </div>
  );
};
