import { Button } from '@netspresso/components';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import PersonalInformationPolicy from '../../assets/mds/personal_Information_policy.md';
import PrivacyPolicy from '../../assets/mds/privacy_policy.md';
import TermsOfServicePolicy from '../../assets/mds/terms_of_service_policy.md';

interface Props {
  agreeType: 'personalAgree' | 'privacyAgree' | 'termsAgree';
  onClickModalConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  onClickModalCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickModalClose?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AgreeModal = ({ agreeType, onClickModalConfirm, onClickModalCancel, onClickModalClose }: Props) => {
  const { t } = useTranslation();
  console.log(PersonalInformationPolicy);
  const { content, title, agreeText, disagreeText, closeText } = {
    personalAgree: {
      content: PersonalInformationPolicy,
      title: t('PERSONAL_AGREE.TITLE'),
      agreeText: t('PERSONAL_AGREE.AGREE_BUTTON'),
      disagreeText: t('PERSONAL_AGREE.DISAGREE_BUTTON'),
      closeText: t('PERSONAL_AGREE.CLOSE_BUTTON'),
    },
    privacyAgree: {
      content: PrivacyPolicy,
      title: t('PRIVACY_AGREE.TITLE'),
      agreeText: t('PRIVACY_AGREE.AGREE_BUTTON'),
      disagreeText: t('PRIVACY_AGREE.DISAGREE_BUTTON'),
      closeText: t('PRIVACY_AGREE.CLOSE_BUTTON'),
    },
    termsAgree: {
      content: TermsOfServicePolicy,
      title: t('TERMS_AGREE.TITLE'),
      agreeText: t('TERMS_AGREE.AGREE_BUTTON'),
      disagreeText: t('TERMS_AGREE.DISAGREE_BUTTON'),
      closeText: t('TERMS_AGREE.CLOSE_BUTTON'),
    },
  }[agreeType];

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="h-[80%] w-[80%] max-w-[1200px] p-8 flex flex-col gap-4 bg-white rounded-lg shadow">
        <div className="h-16">
          <h2 className="text-2xl mt-2 text-black text-center">{title}</h2>
        </div>
        <div className="flex-grow overflow-y-scroll rounded-lg p-4 border overscroll-contain">
          <ReactMarkdown className="prose max-w-full" children={content} />
        </div>
        <div className="flex flex-col gap-4">
          {onClickModalConfirm && (
            <Button type="submit" size="full" shadow color="secondary" onClick={onClickModalConfirm}>
              {agreeText}
            </Button>
          )}
          {onClickModalCancel && (
            <Button type="button" size="full" variant="outline" color="secondary" onClick={onClickModalCancel}>
              {disagreeText}
            </Button>
          )}
          {onClickModalClose && (
            <Button type="button" size="full" variant="outline" color="secondary" onClick={onClickModalClose}>
              {closeText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
