/* eslint-disable camelcase */
import { TextArea, TextInputLabel } from '@netspresso/components';
import { isEmpty } from '@netspresso/shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { useUserAgentFormContext } from '../../../../../contexts';
import { useAgentContext } from '../../../../../contexts/AgentStepContext';
import { useGTM } from '../../../../../hooks';
import { CURRENT_STEPS } from '../../../../../lib/enums';
import { AgentConnectPayload, AgentConnectResponse, AgentService } from '../../../../../library/apis';
import { useLoader } from '../../../../../library/hooks';

export const SetPersonalServer: React.FC = () => {
  const { displayLoader, hideLoader } = useLoader();
  const { setPageToDataLayer } = useGTM();
  const [, setCurrentStep] = useAgentContext();

  const { watch, getValues, setValue, control } = useUserAgentFormContext();
  const { isDirty, errors } = useFormState({ control });

  const [commandResult, setCommandResult] = React.useState('');
  const [showCopied, setShowCopied] = React.useState(false);

  const command = watch('command');

  const isDisabled = () => !isDirty || !isEmpty(errors) || !commandResult;

  const createAgentConnection = (payload: AgentConnectPayload) => {
    return AgentService.createAgentConnection(payload);
  };

  const onSuccessCreateConnection = (data: AxiosResponse<AgentConnectResponse>) => {
    if (data.data.status === 'success') {
      setCurrentStep(CURRENT_STEPS.CONNECT_SERVER);
    } else {
      hideLoader();
      setCurrentStep(CURRENT_STEPS.CONNECT_FAILED);
    }
  };

  const onErrorCreateConnection = (data: AxiosError) => {
    // eslint-disable-next-line no-console
    console.error(data);
  };

  const connectAgent = useMutation({
    mutationFn: createAgentConnection,
    onSuccess: onSuccessCreateConnection,
    onError: onErrorCreateConnection,
  });

  const copyCodeToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  };

  const onCopySampleCode: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyCodeToClipboard(command);

    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  const onClickNext: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    const { name, data_path, agent_env_info } = getValues();

    if (agent_env_info) {
      displayLoader();

      await connectAgent.mutateAsync({
        name,
        data_path,
        agent_env_info,
      });
    }
  };

  const onChangeCommandResult: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setCommandResult(value);
    setValue('result', value, { shouldValidate: true, shouldDirty: true });

    try {
      const parsedResult = JSON.parse(value.replace(/[“”]/g, '"'));

      setValue('agent_env_info', parsedResult, { shouldValidate: true, shouldDirty: true });
    } catch (error) {
      setValue('agent_env_info', null, { shouldValidate: true, shouldDirty: true });
    }
  };

  const onClickBack: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setCurrentStep(CURRENT_STEPS.CREATE_SERVER);
  };

  useEffect(() => {
    setPageToDataLayer('Set Personal Server');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-6 pl-3">Set Personal Server</h1>
      <section className="bg-white rounded-lg shadow p-6 overflow-hidden">
        <div className="w-full overflow-y-scroll">
          <section className="relative mb-6">
            <span className="block font-semibold text-sm text-darkGray mb-1">Command Script *</span>
            <div className="relative">
              <div className="h-[300px] border border-defaultGray rounded p-4 overflow-scroll">
                <pre className="text-xs font-mono">{command}</pre>
                <button type="button" className="absolute top-4 right-4" onClick={onCopySampleCode}>
                  <span className="material-icons text text-defaultGray">content_copy</span>
                </button>
              </div>
            </div>
            {showCopied ? (
              <span className="absolute text-sm text-success-active top-0 right-0">
                Sample code is copied in clipboard.
              </span>
            ) : null}
          </section>
          <section className="flex items-start p-4 rounded bg-blue-50 mt-2">
            <span className="material-icons text-lg mr-2 text-blue-400">info</span>
            <div className="w-full flex justify-between">
              <p className="text-sm text-blue-800 leading-6">
                Please excute the above &ldquo;Command Script&rdquo; on the GPU server and put the results in the
                &ldquo;Command Result&rdquo; field below.
              </p>
            </div>
          </section>
          <section className="my-8">
            <TextInputLabel htmlFor="command-result">Command Result *</TextInputLabel>
            <TextArea
              id="command-result"
              error={errors.result ? errors.result.message : ''}
              placeholder="Paste the script result to activate GPU server."
              rows={10}
              value={commandResult}
              width="w-full"
              onChange={onChangeCommandResult}
            />
          </section>
          <section className="flex justify-end border-t pt-5">
            <button
              type="button"
              className="text-sm text-gray-700 hover:text-sub border border-gray-700 hover:border-sub px-4 py-2 rounded leading-[14px] mr-4"
              onClick={onClickBack}
            >
              Back
            </button>
            <button
              type="button"
              className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-2 rounded leading-[14px] disabled:bg-gray-400"
              disabled={isDisabled()}
              onClick={onClickNext}
            >
              Next
            </button>
          </section>
        </div>
      </section>
    </>
  );
};
