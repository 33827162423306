import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogType, Heading, LabeledTextInput } from '@netspresso/components';
import NetsPressoLogo from '@netspresso/components/assets/logos/logo.svg';
import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Action } from '../../constants';
import { useAuthContext, useModalContext } from '../../contexts';
import { UserService } from '../../library/apis';
import { AuthService, SigninResponse } from '../../library/apis/AuthService';

const SigninDefaultValues = {
  username: '',
  password: '',
};

// todo 외부파일이동
const SigninSchema = z.object({
  username: z.string(),
  password: z.string(),
});

type SigninType = z.infer<typeof SigninSchema>;

export const Signin = () => {
  const { isSignedIn } = useAuthContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { signIn } = useAuthContext();

  const [, dispatchModal] = useModalContext();

  const { control, handleSubmit, getValues } = useForm({
    resolver: zodResolver(SigninSchema),
    defaultValues: SigninDefaultValues,
  });

  const handleSendActivationEmailSuccess = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_SUCCESS_TITLE`)}`}
          infoText={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_SUCCESS_CONTENT`)}`}
          confirmTitle={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_SUCCESS_CONFIRM_BUTTON`)}`}
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleSendActivationEmailError = (error: AxiosError) => {
    if (error.response) {
      const errorCode = error.response.status;

      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_FAILURE_TITLE`)}`}
            infoText={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_${errorCode}`)}`}
            confirmTitle={`${t(`SIGN_IN.SEND_ACTIVATION_EMAIL_FAILURE_CONFIRM_BUTTON`)}`}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const handleClickLogo = () => {
    navigate('/');
  };

  const handleSigninSuccess = (response: AxiosResponse<SigninResponse>) => {
    const { tokens } = response.data;
    const accessToken = tokens.access_token;
    const refreshToken = tokens.refresh_token;

    signIn(accessToken, refreshToken);
    navigate('/');
  };

  const handleSigninError = (error: AxiosError) => {
    if (error.response) {
      const errorCode = error.response.status;

      if (errorCode === 403) {
        dispatchModal({
          type: Action.SHOW,
          payload: (
            <Dialog
              type={DialogType.confirm}
              title={`${t(`SIGN_IN.SIGN_IN_FAILURE_TITLE`)}`}
              infoText={`${t(`SIGN_IN.SIGN_IN_FAILURE_CONTENT_${errorCode}`)}`}
              confirmTitle={`${t(`SIGN_IN.SIGN_IN_FAILURE_CONFIRM_BUTTON`)}`}
              cancelTitle={`${t(`SIGN_IN.SIGN_IN_FAILURE_CANCEL_BUTTON`)}`}
              width="w-[600px]"
              onClickConfirm={() => {
                dispatchModal({ type: Action.HIDE });
                sendEmail({ email: getValues('username') });
              }}
              onClickCancel={() => {
                dispatchModal({ type: Action.HIDE });
              }}
            />
          ),
        });
      } else {
        dispatchModal({
          type: Action.SHOW,
          payload: (
            <Dialog
              type={DialogType.alert}
              title={`${t(`SIGN_IN.SIGN_IN_FAILURE_TITLE`)}`}
              infoText={`${t(`SIGN_IN.SIGN_IN_FAILURE_CONTENT_${errorCode}`)}`}
              confirmTitle={`${t(`SIGN_IN.SIGN_IN_FAILURE_CONFIRM_BUTTON`)}`}
              width="w-[600px]"
              onClickConfirm={() => {
                dispatchModal({ type: Action.HIDE });
              }}
            />
          ),
        });
      }
    }
  };

  const { mutate, isLoading } = useMutation(AuthService.signIn, {
    onSuccess: handleSigninSuccess,
    onError: handleSigninError,
  });

  const { mutate: sendEmail } = useMutation(UserService.sendActiavationEmail, {
    onSuccess: handleSendActivationEmailSuccess,
    onError: handleSendActivationEmailError,
  });

  const handleClickSignin: SubmitHandler<SigninType> = ({ username, password }) => {
    mutate({ username, password });
  };

  const handleInvalidInputs: SubmitErrorHandler<SigninType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  return (
    <div className="h-screen w-screen lg:flex lg:items-center lg:justify-center">
      <div className="mt-16 lg:mt-0">
        <section onClick={handleClickLogo} className="flex justify-center hover:cursor-pointer">
          <NetsPressoLogo transform="scale(1.389)" />
        </section>
        <Heading className="text-2xl mt-2 text-gray-900 text-center">{t('SIGN_IN.TITLE')}</Heading>
        <section className="bg-white py-8 px-8 mt-6 lg:shadow lg:rounded-lg lg:w-[448px]">
          <form className="space-y-4" onSubmit={handleSubmit(handleClickSignin, handleInvalidInputs)}>
            <div>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="username"
                    label={t('SIGN_IN.EMAIL_INPUT')}
                    type="email"
                    autoComplete="email"
                    required
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="password"
                    label={t('SIGN_IN.PASSWORD_INPUT')}
                    type="password"
                    autoComplete="password"
                    required
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link to="/new-password" className="font-medium text-sub hover:text-sub-700">
                  {t('SIGN_IN.FORGET_PASSWORD')}
                </Link>
              </div>
            </div>
            <div>
              <Button size="full" color="secondary" submit shadow disabled={isLoading}>
                {t('SIGN_IN.SIGN_IN_BUTTON')}
              </Button>
            </div>
          </form>
        </section>
        {flag(FEATURE_FLAGS.SignUp) && (
          <>
            <p className="text-center text-sm text-gray-900 mt-4">
              {t('SIGN_IN.SIGN_UP')}{' '}
              <Link to="/signup" className="font-medium text-sub hover:text-sub-700">
                {t('SIGN_IN.SIGN_UP_LINK')}
              </Link>
            </p>
            <p className="text-center text-sm text-gray-900 mt-4">
              {t(`SIGN_UP.ACTIVATE_FAIL`)}{' '}
              <Link to="mailto:netspresso@nota.ai" className="text-sub font-medium hover:text-sub-700">
                {t(`SIGN_UP.CONTACT_US`)}
              </Link>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
