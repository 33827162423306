import {
  COMPRESSOR_DEMO_VIDEO_LINK,
  DMS_LINK,
  DOWNLOAD_FREE_MODELS_LINK,
  ITS_LINK,
  RELEASE_NOTES_LINK,
  SEARCHER_DEMO_VIDEO_LINK,
} from '../../../../constants';

export const NewsList = () => {
  return (
    <section className="px-6 bg-sub">
      <div className="max-w-7xl mx-auto py-8 px-6">
        <div className="grid grid-cols-1 gap-y-8 lg:gap-y-12 lg:grid-cols-4">
          <div className="border-b lg:border-b-0 lg:border-r border-notaBlue-700 pl-6 pr-4 pb-8 lg:pb-0">
            <h3 className="font-title text-xl font-semibold text-white mb-4">Release Notes</h3>
            <ul>
              {RELEASE_NOTES_LINK.slice(0, 3).map(({ label, url }) => {
                return (
                  <li key={label}>
                    <a className="mt-2 text-sm text-white" href={url} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="border-b lg:border-b-0 lg:border-r border-notaBlue-700 pl-6 pr-4 pb-8 lg:pb-0">
            <h3 className="font-title text-xl font-semibold text-white mb-4">Use Cases</h3>
            <ul>
              <li>
                <a className="mt-2 text-sm text-white" href={ITS_LINK} target="_blank" rel="noreferrer">
                  Intelligent Transportation System
                </a>
              </li>
              <li>
                <a className="mt-2 text-sm text-white" href={DMS_LINK} target="_blank" rel="noreferrer">
                  Driver Monitoring System
                </a>
              </li>
            </ul>
          </div>
          <div className="border-b lg:border-b-0 lg:border-r border-notaBlue-700 pl-6 pr-4 pb-8 lg:pb-0 flex justify-between">
            <h3 className="font-title text-xl font-semibold text-white mb-4">
              Download
              <br />
              Free Models
            </h3>
            <a href={DOWNLOAD_FREE_MODELS_LINK} target="_blank" rel="noreferrer">
              <span className="material-icons-outlined text-white">open_in_new</span>
            </a>
          </div>
          <div className="pl-6 pr-4">
            <h3 className="font-title text-xl font-semibold text-white mb-4">Demo Video</h3>
            <ul>
              <li>
                <a className="mt-2 text-sm text-white" href={SEARCHER_DEMO_VIDEO_LINK} target="_blank" rel="noreferrer">
                  Model Searcher
                </a>
              </li>
              <li>
                <a
                  className="mt-2 text-sm text-white"
                  href={COMPRESSOR_DEMO_VIDEO_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Model Compressor
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
