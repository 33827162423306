import { FC, ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ExclamationIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';

interface Props {
  title?: string;
  children?: ReactNode;
  color: 'info' | 'warning' | 'danger' | 'success';
}

const palette = {
  info: {
    background: 'bg-blue-50',
    icon: 'text-blue-400',
    title: 'text-blue-800',
    text: 'text-blue-700',
  },
  success: {
    background: 'bg-green-50',
    icon: 'text-green-400',
    title: 'text-green-800',
    text: 'text-green-700',
  },
  warning: {
    background: 'bg-yellow-50',
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
    text: 'text-yellow-700',
  },
  danger: {
    background: 'bg-red-50',
    icon: 'text-red-400',
    title: 'text-red-800',
    text: 'text-red-700',
  },
};

export const Alert: FC<Props> = ({ title, children, color = 'info' }) => {
  return (
    <div className={`w-full rounded-md p-4 ${palette[color].background}`}>
      <div className="flex">
        <div className={`flex-shrink-0 ${palette[color].icon}`}>
          {color === 'info' && <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />}
          {color === 'success' && <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />}
          {color === 'warning' && <ExclamationIcon className="h-5 w-5" aria-hidden="true" />}
          {color === 'danger' && <XCircleIcon className="h-5 w-5" aria-hidden="true" />}
        </div>
        <div className="ml-3 flex flex-col gap-2">
          {title && <h3 className={`text-sm font-medium ${palette[color].title}`}>{title}</h3>}
          {children && <div className={`text-sm ${palette[color].text}`}>{children}</div>}
        </div>
      </div>
    </div>
  );
};
