import React from 'react';
import { useAgentContext } from '../../../../../../../contexts/AgentStepContext';
import { CURRENT_STEPS } from '../../../../../../../lib/enums';
import { CurrentSteps } from '../../../../../../../lib/types';
import { StepPointer } from '../StepPointer';

export const Steps: React.FC = () => {
  const [currentStep, setCurrentStep] = useAgentContext();

  const onClickTargetStep = (targetStep: CurrentSteps) => () => {
    setCurrentStep(targetStep);
  };

  return (
    <section className="absolute right-8 flex flex-row">
      <button
        type="button"
        className={`flex flex-col items-center -mr-[18px] ${
          currentStep !== CURRENT_STEPS.CREATE_SERVER ? 'cursor-pointer' : 'pointer-events-none'
        }`}
        onClick={onClickTargetStep(CURRENT_STEPS.CREATE_SERVER)}
      >
        <StepPointer
          currentCondition={currentStep === CURRENT_STEPS.CREATE_SERVER}
          checkCondition={currentStep !== CURRENT_STEPS.CREATE_SERVER}
        />
        <p className="w-20 text-xs text-center text-secondary">Create Server</p>
      </button>

      <div
        className={`mt-[9px] -ml-3 w-20 h-0.5 -mr-[18px] ${
          currentStep !== CURRENT_STEPS.CREATE_SERVER ? 'bg-secondary' : 'bg-disabledGray'
        }`}
      />

      <button
        type="button"
        className={`flex flex-col items-center -ml-3 -mr-[18px] ${
          currentStep === CURRENT_STEPS.CONNECT_SERVER || currentStep === CURRENT_STEPS.CONNECT_FAILED
            ? 'cursor-pointer'
            : 'pointer-events-none'
        }`}
        onClick={onClickTargetStep(CURRENT_STEPS.SET_SERVER)}
      >
        <StepPointer
          defaultCondition={currentStep === CURRENT_STEPS.CREATE_SERVER}
          currentCondition={currentStep === CURRENT_STEPS.SET_SERVER}
          checkCondition={currentStep === CURRENT_STEPS.CONNECT_SERVER || currentStep === CURRENT_STEPS.CONNECT_FAILED}
        />
        <p
          className={`w-20 text-xs text-center ${
            currentStep !== CURRENT_STEPS.CREATE_SERVER ? 'text-secondary' : 'text-disabledGray'
          }`}
        >
          Set Server
        </p>
      </button>

      <div
        className={`mt-[9px] -ml-3 w-20 h-0.5 -mr-[22px] ${
          currentStep === CURRENT_STEPS.CONNECT_SERVER || currentStep === CURRENT_STEPS.CONNECT_FAILED
            ? 'bg-secondary'
            : 'bg-disabledGray'
        }`}
      />

      <div className="-ml-3 flex flex-col items-center">
        <StepPointer
          defaultCondition={
            currentStep !== CURRENT_STEPS.CONNECT_SERVER && currentStep !== CURRENT_STEPS.CONNECT_FAILED
          }
          currentCondition={
            currentStep === CURRENT_STEPS.CONNECT_SERVER || currentStep === CURRENT_STEPS.CONNECT_FAILED
          }
        />
        <p
          className={`w-[88px] text-xs text-center ${
            currentStep === CURRENT_STEPS.CONNECT_SERVER || currentStep === CURRENT_STEPS.CONNECT_FAILED
              ? 'text-secondary'
              : 'text-disabledGray'
          }`}
        >
          Connect Server
        </p>
      </div>
    </section>
  );
};
