import { ReactNode, Reducer } from 'react';
import { Action } from '../../constants';

export type ModalState = {
  showModal: boolean;
  children?: ReactNode;
};

export type ModalAction = {
  type: Action;
  payload?: ReactNode;
};

export const modalReducer: Reducer<ModalState, ModalAction> = (_state, action) => {
  switch (action.type) {
    case Action.SHOW:
      return {
        showModal: true,
        children: action.payload,
      };
    case Action.HIDE:
      return {
        showModal: false,
      };
    default:
      throw new Error(`Unhandled action : ${action.type}`);
  }
};
