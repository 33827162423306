import { TextInput, TextInputLabel } from '@netspresso/components';
import { isEmpty } from '@netspresso/shared';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { CONNECT_SERVER_LINK, REACT_QUERY, REQUIREMENTS_LINK, RESOURCES } from '../../../../../constants';
import { useUserAgentFormContext } from '../../../../../contexts';
import { useAgentContext } from '../../../../../contexts/AgentStepContext';
import { useGTM } from '../../../../../hooks';
import { CURRENT_STEPS } from '../../../../../lib/enums';
import { AgentService } from '../../../../../library/apis';
import { useLoader } from '../../../../../library/hooks';
import { useNavigate } from 'react-router';

export const CreatePersonalServer: React.FC = () => {
  const { watch, setValue, control, reset } = useUserAgentFormContext();
  const [, setCurrentStep] = useAgentContext();
  const { displayLoader, hideLoader } = useLoader();
  const navigate = useNavigate();

  const { setPageToDataLayer } = useGTM();

  const { isDirty, errors } = useFormState({ control });
  const [serverName, setServerName] = React.useState('');
  const [datasetPath, setDatasetPath] = React.useState('');
  const [enabled, setEnabled] = React.useState(false);

  const watchedName = watch('name');
  const watchedDataPath = watch('data_path');

  const isDisabled = () => !isDirty || !isEmpty(errors) || !serverName || !datasetPath;

  const onSuccessQuery = (response: AxiosResponse) => {
    setValue('command', response.data.command);

    hideLoader();
    setCurrentStep(CURRENT_STEPS.SET_SERVER);
  };

  const onClickNext: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    displayLoader();

    setEnabled(true);
  };

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setServerName(value);
    setValue('name', value, { shouldValidate: true, shouldDirty: true });
  };

  const onDatapathChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setDatasetPath(value);
    setValue('data_path', value, { shouldValidate: true, shouldDirty: true });
  };

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(RESOURCES);
  };

  const { error } = useQuery([REACT_QUERY.AGENT], () => AgentService.getCommand(datasetPath), {
    enabled,
    onSuccess: onSuccessQuery,
    // eslint-disable-next-line no-console
    onError: (responseError: AxiosError) => console.log(responseError.message),
  });

  useEffect(() => {
    setPageToDataLayer('Create Personal Server');
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <>You&apos;ve got som error</>;
  }

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-6 pl-3">Create Personal Server</h1>
      <section className="bg-white rounded-lg shadow p-6">
        <div className="flex flex-col lg:flex-row pb-8 border-lineGray">
          <form className="w-full">
            <section className="mb-6">
              <TextInputLabel htmlFor="server-name">Name *</TextInputLabel>
              <TextInput
                id="server-name"
                placeholder="Please insert your personal server name"
                value={watchedName || serverName}
                error={errors.name ? errors.name.message : ''}
                width="w-1/2"
                onChange={onNameChange}
              />
            </section>
            <section className="mb-6">
              <TextInputLabel htmlFor="dataset-path">Dataset Path *</TextInputLabel>
              <TextInput
                id="dataset-path"
                placeholder="Please insert your dataset path"
                value={watchedDataPath || datasetPath}
                error={errors.data_path ? errors.data_path.message : ''}
                width="w-1/2"
                onChange={onDatapathChange}
              />
            </section>
            <section className="p-4 rounded bg-blue-50 mb-10">
              <div className="flex items-start">
                <span className="material-icons text-lg mr-2 text-blue-400">info</span>
                <div className="w-full fle flex-col">
                  <p className="text-sm text-blue-800 leading-6">
                    NetsPresso will create and access to &apos;~/NetsPresso&apos; of the personal server.
                    <br />
                    This is necessary to efficiently use the dataset to train AI models faster.
                    <br />
                    Do not modify or delete any files in the directory.
                  </p>
                  <ul>
                    <li>
                      <a
                        href={REQUIREMENTS_LINK}
                        className="inline-flex items-center"
                        target="_blank"
                        role="menuitem"
                        rel="noreferrer"
                      >
                        <span className="material-icons text-lg text-blue-400 mr-1">description</span>
                        <span className="text-sm text-blue-800 leading-6">Requirements</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={CONNECT_SERVER_LINK}
                        className="inline-flex items-center"
                        target="_blank"
                        role="menuitem"
                        rel="noreferrer"
                      >
                        <span className="material-icons text-lg text-blue-400 mr-1">description</span>
                        <span className="text-sm text-blue-800 leading-6">Set & Connect Guide</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="flex justify-end border-t pt-5">
              <button
                type="button"
                className="text-sm text-gray-700 hover:text-sub border border-gray-700 hover:border-sub px-4 py-2 rounded leading-[14px] mr-2"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-2 rounded leading-[14px] disabled:bg-gray-400"
                disabled={isDisabled()}
                onClick={onClickNext}
              >
                Next
              </button>
            </section>
          </form>
        </div>
      </section>
    </>
  );
};
