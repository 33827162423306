import React, { InputHTMLAttributes } from 'react';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  showCharacters?: boolean;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({ showCharacters, ...props }, ref) => {
  return (
    <div className="block">
      <input
        ref={ref}
        {...props}
        type="text"
        className="border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
      />
      {showCharacters && (
        <div className="flex justify-end text-gray-600 text-xs font-semibold font-subtitle">
          {props.value?.toString().length} Characters
        </div>
      )}
    </div>
  );
});
