import { Dialog } from '@netspresso/components';
import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { Link } from 'react-router-dom';
import { ACCOUNT_SETTINGS, Action, CREDIT_LINK, RESOURCES } from '../../../../../../constants';
import { useAuthContext, useModalContext } from '../../../../../../contexts';

export const AccountPanel = () => {
  const [, dispatchModal] = useModalContext();
  const { user, signOut } = useAuthContext();

  const onClickConfirm = () => {
    signOut();
    dispatchModal({ type: Action.HIDE });
  };

  const onClickCancel = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const onClickSignout = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          title="Are you sure you want to sign out?"
          onClickConfirm={onClickConfirm}
          onClickCancel={onClickCancel}
        />
      ),
    });
  };

  return (
    <div
      className="origin-top-right absolute -right-2 mt-2 w-40 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <div className="py-1">
        <a
          href={CREDIT_LINK}
          target="_blank"
          rel="noreferrer"
          className="text-gray-700 block px-4 py-2 text-sm hover:text-sub"
          tabIndex={-1}
        >
          <p className="text-sm">Credit</p>
          <p className="text-sm font-medium text-gray-900 truncate">{user?.credit}</p>
        </a>
      </div>
      <div className="py-1">
        {flag(FEATURE_FLAGS.Account) && (
          <Link to={ACCOUNT_SETTINGS} className="text-gray-700 block px-4 py-2 text-sm hover:text-sub" tabIndex={-1}>
            Account settings
          </Link>
        )}
        <Link to={RESOURCES} className="text-gray-700 block px-4 py-2 text-sm hover:text-sub" tabIndex={-1}>
          Resources
        </Link>
      </div>
      <div className="py-1">
        <button
          type="button"
          className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:text-sub"
          role="menuitem"
          tabIndex={-1}
          onClick={onClickSignout}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
