import { AgentEnvInfo, NotaServer, UserAgent, UserServer } from '../../lib/agent';
import { ClientWithAuth } from './Clients';

export type Agent = {
  user_server: UserServer[];
  nota_server: NotaServer;
};

export type AgentConnectPayload = {
  name: string;
  data_path: string;
  agent_env_info: AgentEnvInfo;
};

export type AgentConnectResponse = { status: 'fail' | 'success'; message: string };

type Command = {
  command: string;
};

export const AgentService = (() => {
  const readAgent = () => {
    return ClientWithAuth.get<Agent>('/agent');
  };

  const getAgent = (id: string) => {
    return ClientWithAuth.get<UserAgent>(`/agent/${id}`);
  };

  const getCommand = (datasetPath: string) => {
    return ClientWithAuth.get<Command>(`/agent/command?dataset_path=${datasetPath}`);
  };

  const createAgentConnection = (payload: AgentConnectPayload) => {
    return ClientWithAuth.post<AgentConnectResponse>('/agent', payload);
  };

  const deleteAgent = (id: string) => {
    return ClientWithAuth.delete(`/agent/${id}`);
  };

  return { readAgent, getAgent, getCommand, deleteAgent, createAgentConnection };
})();
