import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { ModalAction, modalReducer, ModalState } from '../../reducers';

interface Props {
  children: ReactNode;
}

export const ModalContext = createContext<[ModalState, React.Dispatch<ModalAction>] | null>(null);

const initialState = { showModal: false };

export const ModalContextProvider = ({ children }: Props) => {
  const reducer = useReducer(modalReducer, initialState);

  return <ModalContext.Provider value={reducer}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }

  return context;
};
