import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { REACT_QUERY } from '../../constants';
import { User, UserService } from '../../library/apis';
import { Token } from '../../library/utils/Token';

export interface Auth {
  user: User | null;
  isRefreshed: boolean;
  isLoading: boolean;
  isSignedIn: boolean;
  signIn: (accessToken: string, refreshToken: string) => void;
  signOut: () => void;
}

export const useAuth = () => {
  const navigate = useNavigate();

  const [isRefreshed, setIsRefreshed] = useState(true);

  const [isSigninReady, setIsSigninReady] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [user, setUser] = useState<User | null>(null);

  // 새로고침 시 토큰 존재 확인 후 토큰 준비 완료
  useEffect(() => {
    const accessToken = Token.getAccessToken();
    const refreshToken = Token.getRefreshToken();

    if (accessToken && refreshToken) {
      setIsLoading(true);
      setIsSigninReady(true);
    }
    setIsRefreshed(false);
  }, []);

  // 토큰이 준비되면 자동 로그인 시도
  useQuery([REACT_QUERY.USER], UserService.readUser, {
    enabled: isSigninReady,
    // 회원정보 얻기 성공 시 로그인 완료처리
    onSuccess: ({ data }) => {
      setUser(data);
      setIsSignedIn(true);
      setIsLoading(false);

      if (!data.detail_data.privacy_policy_agreement) {
        navigate('/update-account');
      }
    },
  });

  // 로그인 시 토큰을 직접 제공하여 토큰 준비 완료
  const signIn = (accessToken: string, refreshToken: string) => {
    setIsLoading(true);
    Token.setAccessToken(accessToken);
    Token.setRefreshToken(refreshToken);
    setIsSigninReady(true);
  };

  // 로그아웃
  const signOut = () => {
    setIsSigninReady(false);
    setIsSignedIn(false);
    Token.removeTokens();
    navigate('/');
  };

  return { user, isRefreshed, isLoading, isSignedIn, signIn, signOut };
};
