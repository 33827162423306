import NetsPressoLogo from '@netspresso/components/assets/logos/NetsPressoLogo.svg';
import NotaLogo from '@netspresso/components/assets/logos/nota.svg';
import FacebookLogo from '@netspresso/components/assets/logos/facebook.svg';
import TwitterLogo from '@netspresso/components/assets/logos/twitter.svg';
import YoutubeLogo from '@netspresso/components/assets/logos/youtube.svg';
import LinkedinLogo from '@netspresso/components/assets/logos/linkedin.svg';

import {
  CONTACT_LINK,
  FACEBOOK_LINK,
  LINKEDIN_LINK,
  NOTA_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from '../../../../constants';
import { useState } from 'react';
import { PrivacyAgree, TermsAgree } from '../../../../domain/Signup/Agree';

export const Footer = () => {
  const [open, setOpen] = useState({ privacyPolicy: false, termsOfService: false });
  const handleClickPrivacyPolicy = () => {
    setOpen({ ...open, privacyPolicy: true });
  };
  const handleClickTermsOfService = () => {
    setOpen({ ...open, termsOfService: true });
  };
  const handleClickModalClose = () => {
    setOpen({ privacyPolicy: false, termsOfService: false });
  };
  return (
    <footer className="bg-notaNavy-700" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto pt-10 pb-8 px-8">
        <div className="lg:grid lg:grid-cols-2">
          <div className="flex flex-col lg:flex-row mb-8 lg:mb-0">
            <NetsPressoLogo />
            <div className="text-white text-mk lg:-mt-1.5 lg:ml-16 mt-4">
              <p className="mb-2">
                Nota AI NetsPresso is a hardware-aware
                <br />
                AI model optimization platform.
              </p>
              <div className="flex">
                <p className="text-white text-sm mr-2">
                  <span>Contact us : </span>
                  <a href={CONTACT_LINK}>netspresso@nota.ai</a>
                </p>
                <span className="material-icons-outlined text-lg -mt-0.5">email</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-end lg:pr-10">
            <div className="-mt-1 mr-8">
              <p className="text-white text-base mb-3">Company Info</p>
            </div>
            <div>
              <a href={NOTA_LINK} target="_blank" aria-label="Nota" rel="noreferrer">
                <NotaLogo />
              </a>
              <nav className="flex -ml-2 mt-4">
                <a href={FACEBOOK_LINK} target="_blank" aria-label="Facebook" rel="noreferrer">
                  <FacebookLogo />
                </a>
                <a href={TWITTER_LINK} target="_blank" aria-label="Twitter" rel="noreferrer">
                  <TwitterLogo />
                </a>
                <a href={YOUTUBE_LINK} target="_blank" aria-label="YouTube" rel="noreferrer">
                  <YoutubeLogo />
                </a>
                <a href={LINKEDIN_LINK} target="_blank" aria-label="LinkedIn" rel="noreferrer">
                  <LinkedinLogo />
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="pt-8 text-white">
          <span className="text-m lg:mr-20">© 2022-2023 Nota Inc.</span>
          <span className="block lg:inline">
            <button onClick={handleClickPrivacyPolicy} className="text-m font-medium">
              Privacy Policy
            </button>
            <span className="mx-2">|</span>
            <button onClick={handleClickTermsOfService} className="mr-6 text-m font-medium">
              Terms of Service
            </button>
          </span>
        </div>
      </div>
      {open.privacyPolicy && <PrivacyAgree handleClickModalClose={handleClickModalClose} />}

      {open.termsOfService && <TermsAgree handleClickModalClose={handleClickModalClose} />}
    </footer>
  );
};
