import { User } from '../../types';

export const TokenService = (() => {
  const getLocalAccessToken = () => {
    return localStorage.getItem('token');
  };

  const getLocalRefreshToken = () => {
    return localStorage.getItem('refreshToken');
  };

  const updateLocalAccessToken = (token: string) => {
    localStorage.setItem('token', token);
  };

  const updateLocalRefreshToken = (token: string) => {
    localStorage.setItem('refreshToken', token);
  };

  const removeLocalTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem('currentUser')!);
  };

  const setUser = (user: User) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
  };

  const removeUser = () => {
    localStorage.removeItem('currentUser');
  };

  return {
    getLocalAccessToken,
    getLocalRefreshToken,
    updateLocalAccessToken,
    updateLocalRefreshToken,
    removeLocalTokens,
    getUser,
    setUser,
    removeUser,
  };
})();
