import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { RESOURCES } from '../../../../../constants';
import { useUserAgentFormContext } from '../../../../../contexts';
import { useAgentContext } from '../../../../../contexts/AgentStepContext';
import { useGTM } from '../../../../../hooks';
import { CURRENT_STEPS } from '../../../../../lib/enums';

export const FailedServer = () => {
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const [, setCurrentStep] = useAgentContext();
  const { watch } = useUserAgentFormContext();
  const userAgentName = watch('name');

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    navigate(RESOURCES);
  };

  const onClickRetry: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    setCurrentStep(CURRENT_STEPS.CREATE_SERVER);
  };

  useEffect(() => {
    setPageToDataLayer('Failed Connect Personal Server');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-6 pl-3">Connect Personal Server</h1>
      <section className="bg-white rounded-lg shadow p-6">
        <div className="w-full">
          <div className="flex flex-col lg:flex-row pb-8 border-lineGray">
            <section className="w-full">
              <h2 className="text-notaBlue-800 font-title font-bold">Server Information</h2>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Name</div>
                  <div className="text-gray-900 font-normal">{userAgentName}</div>
                </div>
                <div className="w-1/6 mr-6">
                  <div className="text-sm font-medium text-gray-500">Connection status</div>
                  <div className="font-normal text-red-600">Not connected</div>
                </div>
              </section>
            </section>
          </div>
          <div className="flex flex-col lg:flex-row pb-8 border-lineGray">
            <section className="w-full">
              <h2 className="text-notaBlue-800 font-title font-bold">Connection Guide</h2>
              <p>Check the server environment (minimum requirements)</p>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">OS</div>
                  <div className="text-gray-900 font-normal">Ubuntu 18.04 and newer</div>
                </div>
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Hard disk space</div>
                  <div className="text-gray-900 font-normal">512GB available</div>
                </div>
                <div className="w-1/6 mr-6">
                  <div className="text-sm font-medium text-gray-500">Network</div>
                  <div className="text-gray-900 font-normal">Over 100Mbps</div>
                </div>
              </section>
            </section>
          </div>
          <section className="flex items-start p-4 rounded bg-blue-50 mb-6 mt-4">
            <span className="material-icons text-lg mr-2 text-blue-400">info</span>
            <div className="w-full flex justify-between">
              <p className="text-sm text-blue-800 leading-6">
                Please check and set your server environment before the installation.
              </p>
            </div>
          </section>
          <section className="flex justify-end border-t pt-5">
            <button
              type="button"
              className="text-sm text-gray-700 hover:text-sub border border-gray-700 hover:border-sub px-4 py-2 rounded leading-[14px] mr-4"
              onClick={onClickCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-2 rounded leading-[14px] disabled:bg-gray-400"
              onClick={onClickRetry}
            >
              Retry
            </button>
          </section>
        </div>
      </section>
    </>
  );
};
