import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const parseDate = (
  value: string | number | dayjs.Dayjs | Date = Date(),
  format = 'YYYY-MM-DD HH:mm'
): string => {
  return dayjs.utc(value).local().format(format);
};
