/* eslint-disable camelcase */
import { ClientWithAuth, ClientWithoutAuth } from './Clients';

export interface SignupRequest {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  region: string;
  ageAgree: boolean;
  personalAgree: boolean;
  privacyAgree: boolean;
  termsAgree: boolean;
  marketingAgree: boolean;
  redirection_link?: string | null;
}

export type SignupPayload = {
  username: string;
  password: string;
  email: string;
  region: string;
  detail_data: {
    privacy_policy_agreement: boolean;
    personal_information_agreement: boolean;
    accessing_age_agreement: boolean;
    terms_of_service_agreement: boolean;
    marketing_agreement: boolean;
    first_name: string;
    last_name: string;
    company: string;
  };
  redirection_link?: string;
};

export interface ActivationUserRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export type UserAuthorities = [0 | 1, 0 | 1, 0 | 1];

export type UserRegion = 'asia' | 'eu' | 'us';

export interface User {
  user_id: string;
  username: string;
  email: string;
  created_time: string;
  last_login_time: string;
  is_active: 0 | 1;
  detail_data: {
    privacy_policy_agreement: 0 | 1;
    personal_information_agreement: 0 | 1;
    accessing_age_agreement: 0 | 1;
    terms_of_service_agreement: 0 | 1;
    marketing_agreement: 0 | 1;
    first_name: string;
    last_name: string;
    company: string;
  };
  authorities: UserAuthorities;
  region: UserRegion;
  is_admin: 0 | 1;
  is_reset: 1;
  credit: number;
  current_time: string;
}

export interface UpdateUserRequest {
  marketingAgree: boolean;
  firstName: string;
  lastName: string;
  company: string;
  currentPassword: string;
  region?: string;
}

export interface DeleteUserRequest {
  password: string;
}

export const UserService = (() => {
  const signUp = ({
    email,
    password,
    region,
    firstName,
    lastName,
    company,
    ageAgree,
    personalAgree,
    privacyAgree,
    termsAgree,
    marketingAgree,
    redirection_link,
  }: SignupRequest) => {
    const payload: SignupPayload = {
      username: email,
      password,
      email,
      region,
      detail_data: {
        privacy_policy_agreement: privacyAgree,
        personal_information_agreement: personalAgree,
        accessing_age_agreement: ageAgree,
        terms_of_service_agreement: termsAgree,
        marketing_agreement: marketingAgree,
        first_name: firstName,
        last_name: lastName,
        company,
      },
    };

    if (redirection_link) {
      payload.redirection_link = redirection_link;
    }

    return ClientWithoutAuth.post('/auth/local/register', payload);
  };
  const sendActiavationEmail = ({ email }: ActivationUserRequest) => {
    const payload = { email };

    return ClientWithoutAuth.post('/auth/local/activate', payload);
  };

  const resetPassword = ({ email }: ResetPasswordRequest) => {
    const payload = { email };

    return ClientWithoutAuth.post('/auth/local/password', payload);
  };
  const updatePassword = ({ currentPassword, newPassword }: UpdatePasswordRequest) => {
    const payload = { current_password: currentPassword, new_password: newPassword };

    return ClientWithAuth.patch('/user/password', payload);
  };
  const readUser = () => {
    return ClientWithAuth.get<User>('/user');
  };
  const updateUser = ({ currentPassword, marketingAgree, firstName, lastName, company, region }: UpdateUserRequest) => {
    const payload = {
      password: currentPassword,
      ...(region ? { region } : {}),
      detail_data: {
        privacy_policy_agreement: true,
        personal_information_agreement: true,
        accessing_age_agreement: true,
        terms_of_service_agreement: true,
        marketing_agreement: marketingAgree,
        first_name: firstName,
        last_name: lastName,
        company,
      },
    };

    return ClientWithAuth.put<User>('/user', payload);
  };
  const deleteUser = ({ password }: DeleteUserRequest) => {
    const payload = { password };

    return ClientWithAuth.delete('/user', { data: payload });
  };

  return { signUp, sendActiavationEmail, resetPassword, updatePassword, readUser, updateUser, deleteUser };
})();
