import { FC } from 'react';
import { TabButton } from './components';

export type TabIndex = 0 | 1 | 2;

interface Props {
  selectedTab: TabIndex;
  setSelectedTab: React.Dispatch<React.SetStateAction<TabIndex>>;
}

export const ModulesTabNavigator: FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const onTabClickHandler = (index: TabIndex) => () => {
    setSelectedTab(index);
  };

  return (
    <div className="relative h-full lg:w-[1076px] mx-auto">
      <div className="bg-gray-100 rounded-lg font-title p-4">
        <ul className="flex flex-col lg:flex-row justify-between">
          <TabButton
            header="Model Searcher"
            subHeader="Hardware-aware AutoML"
            selected={selectedTab === 0}
            onClick={onTabClickHandler(0)}
          />
          <TabButton
            header="Model Compressor"
            subHeader="Ready-to-use toolkit"
            selected={selectedTab === 1}
            onClick={onTabClickHandler(1)}
          />
          <TabButton
            header="Model Launcher"
            subHeader="Ready-to-deploy package"
            selected={selectedTab === 2}
            onClick={onTabClickHandler(2)}
          />
        </ul>
      </div>
    </div>
  );
};
