export const koKr = {
  SIGN_IN: {
    TITLE: `로그인`,
    EMAIL_INPUT: `이메일 주소`,
    PASSWORD_INPUT: `비밀번호`,
    FORGET_PASSWORD: `비밀번호찾기`,
    SIGN_IN_BUTTON: `로그인`,
    SIGN_UP: `계정이 없다면 회원가입 후 로그인하세요.`,
    SIGN_UP_LINK: `회원가입`,
    SIGN_IN_SUCCESS_TITLE: `로그인 성공`,
    SIGN_IN_SUCCESS_CONTENT: `로그인을 성공하였습니다.`,
    SIGN_IN_SUCCESS_CONFIRM_BUTTON: `확인`,
    SIGN_IN_SUCCESS_CANCEL_BUTTON: `취소`,
    SIGN_IN_FAILURE_TITLE: `로그인 실패`,
    SIGN_IN_FAILURE_CONTENT_0: `서버가 응답하지 않습니다.\n네트워크 또는 서버 상태를 확인해 주세요.`,
    SIGN_IN_FAILURE_CONTENT_400: `로그인을 실패하였습니다.\n비밀번호와 이메일 주소를 다시 확인해 주세요.`,
    SIGN_IN_FAILURE_CONTENT_403: `이메일 주소가 아직 인증되지 않았습니다.\n인증 이메일을 다시 보내시겠습니까?`,
    SIGN_IN_FAILURE_CONTENT_500: `진행 중 서버 에러가 발생하였습니다.\n이후에 다시 시도해 주세요.`,
    SIGN_IN_FAILURE_CONFIRM_BUTTON: `확인`,
    SIGN_IN_FAILURE_CANCEL_BUTTON: `취소`,
    SEND_ACTIVATION_EMAIL_SUCCESS_TITLE: `인증 이메일 전송 성공`,
    SEND_ACTIVATION_EMAIL_SUCCESS_CONTENT: `인증 이메일이 정상적으로 발송되었습니다.`,
    SEND_ACTIVATION_EMAIL_SUCCESS_CONFIRM_BUTTON: `확인`,
    SEND_ACTIVATION_EMAIL_FAILURE_TITLE: `인증 이메일 전송 실패`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_0: `서버가 응답하지 않습니다.\n네트워크 또는 서버 상태를 확인해 주세요.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_400: `유효하지 않은 이메일 주소입니다.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONTENT_500: `진행 중 서버 에러가 발생하였습니다.\n이후에 다시 시도해 주세요.`,
    SEND_ACTIVATION_EMAIL_FAILURE_CONFIRM_BUTTON: `확인`,
  },
  SIGN_UP: {
    TITLE: `회원 가입`,
    EMAIL_INPUT: `이메일 주소`,
    FIRST_NAME_INPUT: `이름`,
    LAST_NAME_INPUT: `성`,
    COMPANY_INPUT: `회사`,
    PASSWORD_INPUT: `비밀번호`,
    CONFIRM_PASSWORD_INPUT: `비밀번호 확인`,
    REGION_INPUT: `접속 지역`,
    REGION_US: `미국`,
    REGION_EU: `유럽`,
    REGION_ASIA: `아시아`,
    AGE_AGREE_INPUT: `<b>만 14세 이상 입니다.</b>`,
    PERSONAL_AGREE_INPUT: `<b>개인 정보 약관</b>에 동의합니다.`,
    PRIVACY_POLICY_INPUT: `<b>프라이버시 정책</b>에 동의합니다.`,
    TERMS_AGREE: `<b>사용 약관</b>에 동의합니다.`,
    MARKETING_AGREE: `관련 기술과 관련하여 노타에서 메일을 수신하거나 기술 지원을 받기 위한 연락 수신에 동의합니다.`,
    SIGN_UP_BUTTON: `회원 가입`,
    SIGN_IN: `이미 계정이 있으신가요?`,
    SIGN_IN_LINK: `로그인`,
    SIGN_UP_SUCCESS_TITLE: `회원 가입 성공`,
    SIGN_UP_SUCCESS_CONTENT: `{{email}}로 회원가입 확인 이메일을 발송하였습니다.`,
    SIGN_UP_SUCCESS_CONFIRM: `확인`,
    SIGN_UP_FAILURE_TITLE: `회원 가입 실패`,
    SIGN_UP_FAILURE_CONTENT_403: `이미 가입되어 있는 이메일 주소 입니다.`,
    SIGN_UP_FAILURE_CONFIRM: `확인`,
    INPUT_VALIDATION_TITLE: `입력 오류`,
    INPUT_VALIDATION_CONFIRM: `확인`,
    INPUT_VALIDATION_INVALID_EMAIL: '이메일 주소 형식이 유효하지 않습니다.',
    INPUT_VALIDATION_TOO_SHORT_FIRST_NAME: '이름은 최소 2자 이상 입력해 주세요.',
    INPUT_VALIDATION_TOO_LONG_FIRST_NAME: '이름은 최대 40자 까지만 입력해 주세요.',
    INPUT_VALIDATION_ALPHABET_ONLY_FIRST_NAME: '이름은 알파벳으로만 입력해 주세요.',
    INPUT_VALIDATION_TOO_SHORT_LAST_NAME: '성은 최소 2자 이상 입력해 주세요.',
    INPUT_VALIDATION_TOO_LONG_LAST_NAME: '성은 최대 40자 까지만 입력해 주세요.',
    INPUT_VALIDATION_ALPHABET_ONLY_LAST_NAME: '성은 알파벳으로만 입력해 주세요.',
    INPUT_VALIDATION_TOO_SHORT_COMPANY: '회사 이름은 최소 2자 이상 입력해 주세요.',
    INPUT_VALIDATION_TOO_LONG_COMPANY: '회사 이름은 최대 40자 까지만 입력해 주세요.',
    INPUT_VALIDATION_ALPHABET_NUMBER_ONLY_COMPANY: '회사 이름은 알파벳 또는 숫자로만 입력해 주세요.',
    INPUT_VALIDATION_INVALID_PASSWORD:
      '비밀번호 형식이 잘못되었습니다.\n각각 한 개 이상의 대문자, 특수문자(!@#$%^&*), 숫자를 포함해 주세요.',
    INPUT_VALIDATION_TOO_SHORT_PASSWORD: '비밀번호는 최소 8자 이상 입력해 주세요.',
    INPUT_VALIDATION_AGE_AGREE: '만 14세 이상임을 동의해 주세요.',
    INPUT_VALIDATION_PERSONAL_AGREE: '개인 정보 약관에 동의해 주세요.',
    INPUT_VALIDATION_PRIVACY_AGREE: '프라이버시 정책에 동의해 주세요.',
    INPUT_VALIDATION_TERMS_AGREE: '사용 약관에 동의해 주세요.',
    INPUT_VALIDATION_NOT_MATCHED_PASSWORD: '입력한 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
  },
  PERSONAL_AGREE: {
    TITLE: '개인 정보 약관',
    AGREE_BUTTON: '개인 정보 약관에 동의합니다.',
    DISAGREE_BUTTON: '동의하지 않습니다.',
    CLOSE_BUTTON: '닫기',
  },
  PRIVACY_AGREE: {
    TITLE: '프라이버시 정책',
    AGREE_BUTTON: '프라이버시 정책에 동의합니다.',
    DISAGREE_BUTTON: '동의하지 않습니다.',
    CLOSE_BUTTON: '닫기',
  },
  TERMS_AGREE: {
    TITLE: '사용 약관',
    AGREE_BUTTON: '사용 약관에 동의합니다.',
    DISAGREE_BUTTON: '동의하지 않습니다.',
    CLOSE_BUTTON: '닫기',
  },
};
