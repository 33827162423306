import { JSXElementConstructor, ReactNode } from 'react';

interface ComposeProps {
  children: ReactNode;
  providers: JSXElementConstructor<{ children: ReactNode }>[];
}

export const Compose = ({ children, providers = [] }: ComposeProps) => {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
};
