import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ACCOUNT_SETTINGS, RESOURCES } from '../../../constants';
import { useGTM } from '../../../hooks';
import { DeleteAccount } from './DeleteUser';
import { UpdatePassword } from './UpdatePassword';
import { UpdateUser } from './UpdateUser';

export const Settings = () => {
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();

  useEffect(() => {
    if (!flag(FEATURE_FLAGS.Account)) {
      navigate(RESOURCES);
    }
    setPageToDataLayer('Account Setting');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="w-full border-b border-lineGray bg-white">
        <div className="max-w-7xl mx-auto px-8">
          <ul className="flex flex-row">
            {flag(FEATURE_FLAGS.Account) && (
              <li>
                <Link
                  className="inline-block font-title font-bold text-gray-700 px-3 pt-2 pb-2 border-b-2 border-subpoint"
                  to={ACCOUNT_SETTINGS}
                >
                  Account Settings
                </Link>
              </li>
            )}
            <li>
              <Link className="inline-block font-title font-bold text-gray-400 px-3 pt-2 pb-2" to={RESOURCES}>
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="w-full">
        <UpdateUser />
        <UpdatePassword />
        <DeleteAccount />
      </section>
    </>
  );
};
