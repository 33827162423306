export const REACT_QUERY = {
  USER: 'USER',
  AGENT: 'AGENT',
  NOTICES: 'NOTICES',
  NOTICE: 'NOTICE',
  RECENT_NOTICE: 'RECENT_NOTICE',
};

export const COOKIE = {
  COOKIE_AGREE: 'cookie_agree',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
};
