import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts';
import { GoogleTagManager } from '../../../../library/utils/GoogleTagManager';

export const CookieConsent = () => {
  const [show, setShow] = useState(true);

  const { isSignedIn } = useAuthContext();

  const handleClickCookieConsentAccept = () => {
    GoogleTagManager.agree();
    GoogleTagManager.initialize();
    setShow(false);
  };

  const handleClickCookieConsentDecline = () => {
    GoogleTagManager.disagree();
    setShow(false);
  };

  useEffect(() => {
    const isAgreed = GoogleTagManager.isAgreed();

    if (show) {
      if (isAgreed === true) {
        // 첫 접속 시 이미 이전에 동의한 적이 있으면 GTM을 활성화 하고 쿠키 동의 화면을 보여주지 않음
        GoogleTagManager.initialize();
        setShow(false);
      } else if (isAgreed === false) {
        // 첫 접속 시 이미 이전에 동의를 거절한 적 있으면 쿠키 동의 화면을 보여주지 않음
        setShow(false);
      } else if (isAgreed === undefined) {
        if (isSignedIn) {
          // 첫 접속 시 동의나 거절을 선택하지 않았지만 로그인이 되어있는 경우 자동 동의 처리하고 GTM을 활성화 하고 화면을 보여주지 않음
          GoogleTagManager.agree();
          GoogleTagManager.initialize();
          setShow(false);
        }
      }
    } else if (!show) {
      if (isSignedIn) {
        if (isAgreed === false || isAgreed === undefined) {
          // 이미 상호작용이 끝났지만 로그인 하는 경우 자동 동의 처리하고 GTM을 활성화 하고 화면을 보여주지 않음
          GoogleTagManager.agree();
          GoogleTagManager.initialize();
        }
      }
    }
  }, [show, isSignedIn]);

  return (
    <>
      {show && (
        <div className="fixed bottom-0 w-full bg-[#003D86]">
          <div className="max-w-7xl mx-auto p-8 flex gap-4 justify-between items-center">
            <div className="text-[12px] text-white">
              This website stores cookies on your computer. We use cookies to improve the user experience and analyze
              the website&apos;s performance and traffic. Please see our Privacy Policy for more information.
            </div>
            <div className="flex justify-between items-center gap-2">
              <button
                type="button"
                onClick={handleClickCookieConsentAccept}
                className="h-[36px] p-1 px-3 bg-white border border-white text-sub rounded hover:bg-sub-600 hover:text-white"
              >
                Accept
              </button>
              <button
                type="button"
                onClick={handleClickCookieConsentDecline}
                className="h-[36px] p-1 px-3 bg-transparent border border-white text-white rounded hover:bg-sub-600 hover:text-white"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
