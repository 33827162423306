import React from 'react';

export type HeadingTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps {
  element?: HeadingTagName;
  children?: React.ReactNode;
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({ element = 'h2', children, className = '' }) => {
  const props = { className: `font-title font-bold ${className}` };
  return React.createElement(element, props, children);
};
