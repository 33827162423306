import { FC, ReactNode } from 'react';

interface Prop {
  children: ReactNode;
  showModal: boolean;
}

export const ModalContainer: FC<Prop> = ({ children, showModal = false }) => {
  return (
    <section
      className={`${
        showModal ? 'block' : 'hidden'
      } flex justify-center items-center h-screen w-full fixed left-0 top-0 bg-black bg-opacity-50 z-20`}
    >
      {children}
    </section>
  );
};
