import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const Time = (() => {
  const UTC2KSTString = (utctime: string) => {
    if (utctime) {
      return dayjs.utc(utctime).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
    }

    return '';
  };

  const UTC2Time = (utctime: string) => {
    if (utctime) {
      return dayjs.utc(utctime).toDate();
    }

    return new Date();
  };

  const KST2UnixTimestamp = (kst: Date) => {
    return dayjs.tz(kst, 'Asia/Seoul').unix();
  };

  return {
    UTC2KSTString,
    UTC2Time,
    KST2UnixTimestamp,
  };
})();
