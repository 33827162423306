import { Dialog } from '@netspresso/components';
import { formatBytes, parseDate, sortByCreatedTime } from '@netspresso/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Action, REACT_QUERY, RESOURCES } from '../../../../../constants';
import { useModalContext } from '../../../../../contexts';
import { useGTM } from '../../../../../hooks';
import { UserAgent } from '../../../../../lib/agent';
import { Agent, AgentService } from '../../../../../library/apis';
import { useLoader } from '../../../../../library/hooks';
import { parseServerColor, parseServerStatus } from '../../../../../library/utils';

export const ConnectPersonalServer = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const [, dispatchModal] = useModalContext();
  const { displayLoader, hideLoader } = useLoader();
  const [userAgent, setUserAgent] = React.useState<UserAgent | null>(null);
  const [showCopied, setShowCopied] = React.useState(false);
  const [activateCommand, setActivateCommand] = React.useState('');

  const copyCodeToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  };

  const onCopySampleCode: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyCodeToClipboard(activateCommand);

    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  const onSuccessAgents = async (response: AxiosResponse<Agent>) => {
    const userServers = response.data.user_server;

    const nonActivated = sortByCreatedTime(userServers).filter((server) => {
      if (uuid) {
        return server.agent_id === uuid;
      }

      return server.status === 'not_available';
    });

    if (nonActivated.length > 0) {
      const agent = await AgentService.getAgent(nonActivated[0].agent_id);

      setUserAgent(agent.data);
      setActivateCommand(agent.data.connect_script);
    }
    dispatchModal({ type: Action.HIDE });
  };

  const onClickConfirm: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    navigate(RESOURCES);
  };

  useQuery({ queryKey: [REACT_QUERY.AGENT], queryFn: AgentService.readAgent, onSuccess: onSuccessAgents });

  const deleteUserAgent = (agentId: string) => {
    hideLoader();
    displayLoader();

    return AgentService.deleteAgent(agentId);
  };

  const onSuccessDelete = () => {
    navigate(RESOURCES);
  };

  const onErrorDelete = (data: AxiosError) => {
    // eslint-disable-next-line no-console
    console.error(data);

    hideLoader();
  };

  const deleteServer = useMutation({
    mutationFn: deleteUserAgent,
    onSuccess: onSuccessDelete,
    onError: onErrorDelete,
  });

  const onClickConfirmDelete = async (agentId: string) => {
    await deleteServer.mutateAsync(agentId);
  };

  const onClickCancel = () => {
    hideLoader();
  };

  const onClickDelete = (agentId: string) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          title="Are you sure you want to delete?"
          infoText="The selected server will be deleted."
          onClickConfirm={() => onClickConfirmDelete(agentId)}
          onClickCancel={onClickCancel}
        />
      ),
    });
  };

  useEffect(() => {
    setPageToDataLayer('Connect Personal Server');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userAgent) {
    return <></>;
  }

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-6 pl-3">Connect Personal Server</h1>
      <section className="bg-white rounded-lg shadow p-6">
        <div className="w-full">
          <div className="flex flex-col lg:flex-row pb-8 border-lineGray">
            <section className="w-full">
              <h2 className="text-notaBlue-800 font-title font-bold">Server Information</h2>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Name</div>
                  <div className="text-gray-900 font-normal">{userAgent.name}</div>
                </div>
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Connection status</div>
                  <div className={`font-normal ${parseServerColor(userAgent.status)}`}>
                    {parseServerStatus(userAgent.status)}
                  </div>
                </div>
                <div className="w-1/6 mr-6">
                  <div className="text-sm font-medium text-gray-500">Created time</div>
                  <div className="text-gray-900 font-normal">{parseDate(userAgent.created_time)}</div>
                </div>
              </section>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">OS</div>
                  <div className="text-gray-900 font-normal">{userAgent.os}</div>
                </div>
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">CPU model</div>
                  <div className="text-gray-900 font-normal">{userAgent.cpu_model_name}</div>
                </div>
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">CPU cores</div>
                  <div className="text-gray-900 font-normal">{userAgent.cpu_cores}</div>
                </div>
                <div className="w-1/6 mr-6">
                  <div className="text-sm font-medium text-gray-500">CPU memory</div>
                  <div className="text-gray-900 font-normal">{formatBytes(userAgent.cpu_memory_size)}</div>
                </div>
              </section>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Docker version</div>
                  <div className="text-gray-900 font-normal">{userAgent.docker_version}</div>
                </div>
                <div className="w-1/2 mr-6">
                  <div className="text-sm font-medium text-gray-500">Host env</div>
                  <div className="text-gray-900 font-normal">{userAgent.host_env}</div>
                </div>
              </section>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-full mr-6">
                  <div className="text-sm font-medium text-gray-500">GPUs info</div>
                  <div className="text-gray-900 font-normal">{JSON.stringify(userAgent.gpus_info)}</div>
                </div>
              </section>
              <section className="flex flex-row mb-4 pt-4">
                <div className="w-1/6 border-r border-lineGray mr-6">
                  <div className="text-sm font-medium text-gray-500">Last activated time</div>
                  <div className="text-gray-900 font-normal">
                    {userAgent.last_activated_time ? parseDate(userAgent.last_activated_time) : 'N/A'}
                  </div>
                </div>
                <div className="w-1/6 mr-6">
                  <div className="text-sm font-medium text-gray-500">Last alive time</div>
                  <div className="text-gray-900 font-normal">
                    {userAgent.last_alive_time ? parseDate(userAgent.last_alive_time) : 'N/A'}
                  </div>
                </div>
              </section>
            </section>
          </div>
          <section className="relative mb-6">
            <span className="block font-semibold text-sm text-darkGray mb-1">Connect Script</span>
            <div className="relative">
              <div className="h-[100px] border border-defaultGray rounded p-4 overflow-scroll">
                <pre className="text-xs font-mono">{activateCommand}</pre>
                <button type="button" className="absolute top-4 right-4" onClick={onCopySampleCode}>
                  <span className="material-icons text text-defaultGray">content_copy</span>
                </button>
              </div>
            </div>
            {showCopied ? (
              <span className="absolute text-sm text-success-active top-0 right-0">
                Sample code is copied in clipboard.
              </span>
            ) : null}
          </section>
          <section className="flex items-start p-4 rounded bg-blue-50 mb-6 mt-4">
            <span className="material-icons text-lg mr-2 text-blue-400">info</span>
            <div className="w-full flex justify-between">
              <p className="text-sm text-blue-800 leading-6">
                Please execute the above Connect Script on the GPU server.
                <br />
                It is not necessary to connect it now as it can be done later.
              </p>
            </div>
          </section>
          <section className="flex justify-end border-t pt-5">
            <button
              type="button"
              className="text-sm text-gray-700 hover:text-sub border border-gray-700 hover:border-sub px-4 py-2 rounded leading-[14px] mr-4"
              onClick={() => onClickDelete(userAgent.agent_id)}
            >
              Delete
            </button>
            <button
              type="button"
              className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-2 rounded leading-[14px] disabled:bg-gray-400"
              onClick={onClickConfirm}
            >
              Confirm
            </button>
          </section>
        </div>
      </section>
    </>
  );
};
