import { FC } from 'react';

interface Props {
  header: string;
  subHeader: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const TabButton: FC<Props> = ({ header, subHeader, selected, onClick }) => {
  return (
    <li className={`text-center py-7 ${selected && 'bg-sub rounded-lg'}`}>
      <div className={`lg:w-[348px] ${selected ? 'cursor-default' : 'group cursor-pointer'}`} onClick={onClick}>
        <h2
          className={`font-bold text-3xl mb-2 ${selected ? 'text-white' : 'text-gray-400 group-hover:text-gray-600'}`}
        >
          {header}
        </h2>
        <p className={`text-xl ${selected ? 'text-subpoint' : 'text-gray-400 group-hover:text-gray-600'}`}>
          {subHeader}
        </p>
      </div>
    </li>
  );
};
