export const Launcher = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between lg:w-[894px] mx-auto pt-10 px-8 lg:px-0 lg:pt-18">
      <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
        <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Quantization</h1>
        <ul className="list-disc text-m">
          <li>Quantization enables to run and accelerate an AI model on the target device.</li>
        </ul>
      </div>
      <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
        <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Convert and package</h1>
        <ul className="list-disc text-m">
          <li>Convert and compile AI models so that hardware can understand and run them. (Various options) </li>
          <li>Package AI models with processing codes to be ready for the deployment</li>
        </ul>
      </div>
      <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8">
        <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Device farm</h1>
        <ul className="list-disc text-m">
          <li>Actual hardware are already installed in NetsPresso Device Farm to provide hardware-aware features.</li>
        </ul>
      </div>
    </div>
  );
};
