import { createContext, ReactNode, useContext } from 'react';
import { Auth, useAuth } from '../../hooks';

interface Props {
  children: ReactNode;
}

export const AuthContext = createContext<Auth | null>(null);

export const AuthContextProvider = ({ children }: Props) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};
