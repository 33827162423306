import { Button, Checkbox, Dialog, DialogType, Heading, LabeledTextInput, Select } from '@netspresso/components';
import NetsPressoLogo from '@netspresso/components/assets/logos/logo.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import * as z from 'zod';
import Required from '../../components/Portal/components/Required/Required';
import { Action, REGION_OPTIONS } from '../../constants';
import { useModalContext } from '../../contexts';
import { UserService } from '../../library/apis/UserService';
import { AgreeModal } from './AgreeModal';

const SignupDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  password: '',
  confirmPassword: '',
  region: 'us',
  ageAgree: false,
  personalAgree: false,
  privacyAgree: false,
  termsAgree: false,
  marketingAgree: false,
};

// todo 외부파일이동
const SignupSchema = z
  .object({
    email: z.string().email(`SIGN_UP.INPUT_VALIDATION_INVALID_EMAIL`),
    firstName: z
      .string()
      .min(2, `SIGN_UP.INPUT_VALIDATION_TOO_SHORT_FIRST_NAME`)
      .max(40, `SIGN_UP.INPUT_VALIDATION_TOO_LONG_FIRST_NAME`)
      .regex(/^[a-zA-Z]/, `SIGN_UP.INPUT_VALIDATION_ALPHABET_ONLY_FIRST_NAME`),
    lastName: z
      .string()
      .min(2, `SIGN_UP.INPUT_VALIDATION_TOO_SHORT_LAST_NAME`)
      .max(40, `SIGN_UP.INPUT_VALIDATION_TOO_LONG_LAST_NAME`)
      .regex(/^[a-zA-Z]/, `SIGN_UP.INPUT_VALIDATION_ALPHABET_ONLY_LAST_NAME`),
    company: z
      .string()
      .min(2, `SIGN_UP.INPUT_VALIDATION_TOO_SHORT_COMPANY`)
      .max(40, `SIGN_UP.INPUT_VALIDATION_TOO_LONG_COMPANY`)
      .regex(/^[0-9a-zA-Z]/, `SIGN_UP.INPUT_VALIDATION_ALPHABET_NUMBER_ONLY_COMPANY`)
      .or(z.literal('')),
    password: z
      .string()
      .regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{1,}$/, `SIGN_UP.INPUT_VALIDATION_INVALID_PASSWORD`)
      .min(8, `SIGN_UP.INPUT_VALIDATION_TOO_SHORT_PASSWORD`),
    redirection_link: z.string().optional().nullable(),
    confirmPassword: z.string(),
    region: z.string(),
    ageAgree: z.boolean().refine((value) => value, `SIGN_UP.INPUT_VALIDATION_AGE_AGREE`),
    personalAgree: z.boolean().refine((value) => value, `SIGN_UP.INPUT_VALIDATION_PERSONAL_AGREE`),
    privacyAgree: z.boolean().refine((value) => value, `SIGN_UP.INPUT_VALIDATION_PRIVACY_AGREE`),
    termsAgree: z.boolean().refine((value) => value, `SIGN_UP.INPUT_VALIDATION_TERMS_AGREE`),
    marketingAgree: z.boolean(),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: `SIGN_UP.INPUT_VALIDATION_NOT_MATCHED_PASSWORD`,
    path: ['confirmPassword'],
  });

type SignupType = z.infer<typeof SignupSchema>;

export const Signup = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [open, setOpen] = useState({
    personalAgree: false,
    privacyAgree: false,
    termsAgree: false,
  });

  const [, dispatchModal] = useModalContext();

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: zodResolver(SignupSchema),
    defaultValues: SignupDefaultValues,
  });

  const showSuccessSignup = () => {
    const email = getValues('email');

    if (searchParams.get('redirection_link')) {
      window.close();
    }

    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title={`${t(`SIGN_UP.SIGN_UP_SUCCESS_TITLE`)}`}
          infoText={<Trans i18nKey="SIGN_UP.SIGN_UP_SUCCESS_CONTENT" values={{ email: email }} />}
          confirmTitle={`${t(`SIGN_UP.SIGN_UP_SUCCESS_CONFIRM`)}`}
          width="w-[600px]"
          onClickConfirm={() => {
            navigate('/');
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const show403Error = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title={`${t(`SIGN_UP.SIGN_UP_FAILURE_TITLE`)}`}
          infoText={`${t(`SIGN_UP.SIGN_UP_FAILURE_CONTENT_403`)}`}
          confirmTitle={`${t(`SIGN_UP.SIGN_UP_FAILURE_CONFIRM`)}`}
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleSignupSuccess = () => {
    showSuccessSignup();
  };

  const handleSignupError = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 403) {
        show403Error();
      }
    }
  };

  const { mutate, isLoading } = useMutation(UserService.signUp, {
    onSuccess: handleSignupSuccess,
    onError: handleSignupError,
  });

  const handleClickSignup: SubmitHandler<SignupType> = ({
    email,
    firstName,
    lastName,
    company,
    password,
    region,
    ageAgree,
    personalAgree,
    privacyAgree,
    termsAgree,
    marketingAgree,
  }) => {
    const payload: Omit<SignupType, 'confirmPassword'> = {
      email,
      firstName,
      lastName,
      company,
      password,
      region,
      ageAgree,
      personalAgree,
      privacyAgree,
      termsAgree,
      marketingAgree,
      redirection_link: searchParams.get('redirection_link') || `${process.env.REACT_APP_WEB_URL}/signin`,
    };

    mutate(payload);
  };

  const handleInvalidInputs: SubmitErrorHandler<SignupType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title={`${t(`SIGN_UP.INPUT_VALIDATION_TITLE`)}`}
            infoText={`${t((message as string) || '')}`}
            // `${i18next.t(`SIGN_UP.INPUT_VALIDATION_INVALID_EMAIL`)}`
            confirmTitle={`${t(`SIGN_UP.INPUT_VALIDATION_CONFIRM`)}`}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickAgree: React.MouseEventHandler<HTMLInputElement> = (e) => {
    const { id } = e.currentTarget;

    switch (id) {
      case 'personal-agree':
        setOpen({ ...open, personalAgree: true });
        break;
      case 'privacy-agree':
        setOpen({ ...open, privacyAgree: true });
        break;
      case 'terms-agree':
        setOpen({ ...open, termsAgree: true });
        break;
    }
  };

  const handleClickModalConfirm =
    (type: 'personalAgree' | 'privacyAgree' | 'termsAgree'): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      switch (type) {
        case 'personalAgree':
          setValue('personalAgree', true);
          break;
        case 'privacyAgree':
          setValue('privacyAgree', true);
          break;
        case 'termsAgree':
          setValue('termsAgree', true);
          break;
      }
      setOpen({ ...open, [type]: false });
    };

  const handleClickModalCancel =
    (type: 'personalAgree' | 'privacyAgree' | 'termsAgree'): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      switch (type) {
        case 'personalAgree':
          setValue('personalAgree', false);
          break;
        case 'privacyAgree':
          setValue('privacyAgree', false);
          break;
        case 'termsAgree':
          setValue('termsAgree', false);
          break;
      }
      setOpen({ ...open, [type]: false });
    };

  const handleClickLogo = () => {
    navigate('/');
  };

  return (
    <div className="w-screen flex items-center justify-center pb-16">
      <div className="mt-16">
        <section
          role="button"
          tabIndex={-1}
          onClick={handleClickLogo}
          className="flex justify-center hover:cursor-pointer"
        >
          <NetsPressoLogo transform="scale(1.389)" />
        </section>
        <Heading className="text-2xl mt-2 text-gray-900 text-center">{t(`SIGN_UP.TITLE`)}</Heading>
        <section className="bg-white py-8 px-8 mt-6 lg:shadow lg:rounded-lg lg:w-[448px]">
          <form className="space-y-4" onSubmit={handleSubmit(handleClickSignup, handleInvalidInputs)}>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="email"
                    label={
                      <>
                        {t(`SIGN_UP.EMAIL_INPUT`)}
                        <Required />
                      </>
                    }
                    type="email"
                    autoComplete="email"
                    required
                  />
                )}
              />
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="mb-4 lg:mb-0 lg:w-1/2">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <LabeledTextInput
                      {...field}
                      id="firstName"
                      className="lg:pr-2"
                      label={
                        <>
                          {t(`SIGN_UP.FIRST_NAME_INPUT`)}
                          <Required />
                        </>
                      }
                      required
                    />
                  )}
                />
              </div>
              <div className="lg:w-1/2">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <LabeledTextInput
                      {...field}
                      id="lastName"
                      className="lg:pl-2"
                      label={
                        <>
                          {t(`SIGN_UP.LAST_NAME_INPUT`)}
                          <Required />
                        </>
                      }
                      required
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput {...field} id="company" label={<>{t(`SIGN_UP.COMPANY_INPUT`)}</>} />
                )}
              />
            </div>
            <div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="password"
                    type="password"
                    label={
                      <>
                        {t(`SIGN_UP.PASSWORD_INPUT`)}
                        <Required />
                      </>
                    }
                    autoComplete="current-password"
                    required
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="confirm-password"
                    type="password"
                    label={
                      <>
                        {t(`SIGN_UP.CONFIRM_PASSWORD_INPUT`)}
                        <Required />
                      </>
                    }
                    required
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="region">
                {t(`SIGN_UP.REGION_INPUT`)}
                <Required />
              </label>
              <Controller
                name="region"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="region"
                    options={REGION_OPTIONS.map((option) => {
                      return {
                        name: t(`SIGN_UP.REGION_${option.name}`),
                        value: option.value,
                      };
                    })}
                  />
                )}
              />
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="ageAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="age-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="age-agree">
                <Trans i18nKey="SIGN_UP.AGE_AGREE_INPUT" />
                <Required />
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="personalAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="personal-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="personal-agree">
                <Trans i18nKey="SIGN_UP.PERSONAL_AGREE_INPUT" />
                <Required />
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="privacyAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="privacy-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="privacy-agree">
                <Trans i18nKey="SIGN_UP.PRIVACY_POLICY_INPUT" />
                <Required />
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="termsAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="terms-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="terms-agree">
                <Trans i18nKey="SIGN_UP.TERMS_AGREE" />
                <Required />
              </label>
            </div>
            <div className="flex flex-row">
              <Controller
                name="marketingAgree"
                control={control}
                render={({ field }) => <Checkbox {...field} onClick={handleClickAgree} id="marketing-agree" />}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="marketing-agree">
                <Trans i18nKey="SIGN_UP.MARKETING_AGREE" />
              </label>
            </div>
            <div>
              <Button color="secondary" size="full" submit shadow disabled={isLoading}>
                {t(`SIGN_UP.SIGN_UP_BUTTON`)}
              </Button>
            </div>
          </form>
        </section>
        <p className="text-center text-sm text-gray-900 mt-4">
          {t(`SIGN_UP.SIGN_IN`)}{' '}
          <Link to="/signin" className="text-sub font-medium hover:text-sub-700">
            {t(`SIGN_UP.SIGN_IN_LINK`)}
          </Link>
        </p>
        <p className="text-center text-sm text-gray-900 mt-4">
          {t(`SIGN_UP.ACTIVATE_FAIL`)}{' '}
          <Link to="mailto:netspresso@nota.ai" className="text-sub font-medium hover:text-sub-700">
            {t(`SIGN_UP.CONTACT_US`)}
          </Link>
        </p>
      </div>
      {open.personalAgree && (
        <AgreeModal
          agreeType="personalAgree"
          onClickModalConfirm={handleClickModalConfirm('personalAgree')}
          onClickModalCancel={handleClickModalCancel('personalAgree')}
        />
      )}
      {open.privacyAgree && (
        <AgreeModal
          agreeType="privacyAgree"
          onClickModalConfirm={handleClickModalConfirm('privacyAgree')}
          onClickModalCancel={handleClickModalCancel('privacyAgree')}
        />
      )}
      {open.termsAgree && (
        <AgreeModal
          agreeType="termsAgree"
          onClickModalConfirm={handleClickModalConfirm('termsAgree')}
          onClickModalCancel={handleClickModalCancel('termsAgree')}
        />
      )}
    </div>
  );
};
