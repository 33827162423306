/* eslint-disable camelcase */
import axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { Token } from '../utils/Token';
import { AuthService } from './AuthService';
import { UserAuthorities, UserRegion } from './UserService';

export const ClientWithoutAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const ClientWithAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

interface JWT {
  authorities: UserAuthorities;
  exp: number;
  region: UserRegion;
  session_id: string;
  user_id: string;
}

let isRefreshing = false;

const setIsRefreshing = (bool: boolean) => {
  isRefreshing = bool;
};

const failedTaskQueue: any[] = [];

const enroleFailedTask = (request: AxiosRequestConfig, resolve: any) => {
  failedTaskQueue.push([request, resolve]);
};

const resetFailedTask = () => {
  failedTaskQueue.length = 0;
};

const resolveFailedTask = () => {
  const accessToken = Token.getAccessToken();

  while (true) {
    const failedTask = failedTaskQueue.shift();

    if (failedTask) {
      const request = failedTask[0];
      const resolve = failedTask[1];

      request.headers.Authorization = `Bearer ${accessToken}`;
      resolve(request);
    } else {
      break;
    }
  }
};

export const renewToken = async () => {
  setIsRefreshing(true);

  try {
    const accessToken = Token.getAccessToken();
    const refreshToken = Token.getRefreshToken();
    const response = await AuthService.createAccessToken({
      accessToken,
      refreshToken,
    });
    // 토큰 발급 성공: 쌓였던 실패 요청 재실행
    const {
      tokens: { access_token, refresh_token },
    } = response.data;

    Token.setAccessToken(access_token);
    Token.setRefreshToken(refresh_token);
    resolveFailedTask();
  } catch (error) {
    // 토큰 발급 실패: 토큰 삭제 후 로그인 페이지로 이동
    Token.removeTokens();
    resetFailedTask();
    alert('Please login again');
    // todo: 추후수정
    window.location.assign('/signin');
  }
  setIsRefreshing(false);
};

ClientWithAuth.interceptors.request.use(
  (request) => {
    const accessToken = Token.getAccessToken();
    const refreshToken = Token.getRefreshToken();

    if (accessToken && refreshToken && request.headers) {
      // 토큰이 존재하는 경우: 유효시간 확인 후 재발급
      const expUnix = jwtDecode<JWT>(accessToken).exp;
      const curUnix = dayjs().unix();

      if (expUnix - curUnix < 60) {
        // 유효시간이 60초 이하로 남은 경우
        if (!isRefreshing) {
          renewToken();
        }

        return new Promise((resolve) => {
          enroleFailedTask(request, resolve);
        });
      }
      // 유효시간이 충분한 경우
      request.headers.Authorization = `Bearer ${accessToken}`;

      return Promise.resolve(request);
    }

    // 토큰이 없는 경우: 비정상적인 접근
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);
