import { FIELD_NAME } from '@netspresso/shared';
import { z } from 'zod';
import { WRONG_CHARACTERS } from '../constants';
import { FormErrorMessages } from '../constants/errors/FormErrorMessages';

export const UserAgentFormScheme = z.object({
  name: z
    .string({
      required_error: 'Name is required',
    })
    .nonempty()
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .regex(FIELD_NAME, WRONG_CHARACTERS),
  data_path: z
    .string({
      required_error: 'Data path is required',
    })
    .nonempty()
    .min(2, FormErrorMessages.isNotMin(2)),
  command: z.string(),
  result: z.string(),
  agent_env_info: z
    .object({
      os: z.string(),
      cpu_cores: z.number(),
      cpu_model_name: z.string(),
      cpu_memory_size: z.number(),
      docker_version: z.string(),
      gpus_info: z.array(
        z.object({
          index: z.number(),
          model_name: z.string(),
          memory_size: z.number(),
          driver_version: z.string(),
        })
      ),
      system_uuid: z.string(),
      host_env: z.boolean(),
    })
    .nullable(),
});

export const UserAgentFormDefaultValues = {
  name: '',
  data_path: '',
  command: '',
  result: '',
  agent_env_info: null,
};

export type UserAgentFormType = z.infer<typeof UserAgentFormScheme>;
