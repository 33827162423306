import { ServerStatus } from '../../../lib/agent';

export const parseServerStatus = (status: ServerStatus) => {
  switch (status) {
    case 'available':
      return 'Available';
    case 'running':
      return 'Running';
  }

  return 'Not Available';
};
