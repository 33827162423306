import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogType, Spinner } from '@netspresso/components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SelectBox } from '../../../../components/Portal/components/SelectBox';
import { TextField } from '../../../../components/Portal/components/TextField';
import { Action, NOTICES } from '../../../../constants';
import {
  COMMON_ERROR_MESSAGE,
  INVALID_NOTICE_ANNOUNCE_EXPIRY_TIME,
  INVALID_NOTICE_ANNOUNCE_START_TIME,
  INVALID_NOTICE_ANNOUNCE_TIME,
  INVALID_NOTICE_COOKIE_LIFE_TIME,
  INVALID_NOTICE_LINK_URL,
  INVALID_RELEASE_VERSION,
  MAXIMUM_NOTICE_COOKIE_LIFE_TIME,
  MINIMUM_NOTICE_COOKIE_LIFE_TIME,
  NEED_NOTICE_CONTENT_TITLE,
  NEED_NOTICE_TITLE,
  TOO_LONG_NOTICE_CONTENT_TITLE,
  TOO_LONG_NOTICE_TITLE,
} from '../../../../constants/errors';
import { useModalContext } from '../../../../contexts';
import { useGTM } from '../../../../hooks';
import { Notice, NoticeService } from '../../../../library/apis';
import { Time } from '../../../../library/utils';

const NewNoticeSchema = z
  .object({
    title: z.string().min(1, NEED_NOTICE_TITLE).max(30, TOO_LONG_NOTICE_TITLE),
    content: z.string().min(1, NEED_NOTICE_CONTENT_TITLE).max(200, TOO_LONG_NOTICE_CONTENT_TITLE),
    linkUrl: z.string().regex(/^(http:\/\/|https:\/\/).+\..+$|^$/, INVALID_NOTICE_LINK_URL),
    announceStartTime: z.date({
      required_error: INVALID_NOTICE_ANNOUNCE_START_TIME,
      invalid_type_error: INVALID_NOTICE_ANNOUNCE_START_TIME,
    }),
    announceExpiryTime: z.date({
      required_error: INVALID_NOTICE_ANNOUNCE_EXPIRY_TIME,
      invalid_type_error: INVALID_NOTICE_ANNOUNCE_EXPIRY_TIME,
    }),
    cookieLifeTime: z.preprocess(
      Number,
      z
        .number()
        .int(INVALID_NOTICE_COOKIE_LIFE_TIME)
        .min(1, MINIMUM_NOTICE_COOKIE_LIFE_TIME)
        .max(999, MAXIMUM_NOTICE_COOKIE_LIFE_TIME)
    ),
    noticeType: z.enum(['release', 'maintenance', 'promotion', 'news', 'etc']),
    releaseVersion: z.string().regex(/^[0-9]+\.[0-9]+\.[0-9]+$|^$/, INVALID_RELEASE_VERSION),
  })
  .refine(({ announceStartTime, announceExpiryTime }) => announceStartTime < announceExpiryTime, {
    message: INVALID_NOTICE_ANNOUNCE_TIME,
    path: ['announceExpiryTime'],
  });

type NewNoticeType = z.infer<typeof NewNoticeSchema>;

const NewNoticeDefaultValues: NewNoticeType = {
  title: '',
  content: '',
  linkUrl: '',
  announceStartTime: new Date(),
  announceExpiryTime: new Date(),
  cookieLifeTime: 24,
  noticeType: 'release',
  releaseVersion: '',
};

export const NewNotice = () => {
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const [, dispatchModal] = useModalContext();

  const { control, handleSubmit } = useForm<NewNoticeType>({
    resolver: zodResolver(NewNoticeSchema),
    defaultValues: NewNoticeDefaultValues,
  });

  const handleNewNoticeSuccess = (_: AxiosResponse<Notice>): void => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="New notice success"
          infoText="New notice has been saved."
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
    navigate(NOTICES);
  };

  const handleNewNoticeError = (error: AxiosError) => {
    const errorCode = error.code || '';

    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="New notice failed"
          infoText={`New notice has been not saved.\n${COMMON_ERROR_MESSAGE[errorCode || '']}`}
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
  };

  const { mutate, isLoading } = useMutation(NoticeService.createNotice, {
    onSuccess: handleNewNoticeSuccess,
    onError: handleNewNoticeError,
  });

  const handleClickNewNotice: SubmitHandler<NewNoticeType> = ({
    title,
    content,
    linkUrl,
    announceStartTime,
    announceExpiryTime,
    cookieLifeTime,
    noticeType,
    releaseVersion,
  }) => {
    mutate({
      title,
      content,
      linkUrl,
      announceStartTime: Time.KST2UnixTimestamp(announceStartTime),
      announceExpiryTime: Time.KST2UnixTimestamp(announceExpiryTime),
      cookieLifeTime,
      noticeType,
      releaseVersion,
    });
  };

  const handleInvalidInputs: SubmitErrorHandler<NewNoticeType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickConfirm = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const handleClickCancel = () => {
    navigate(NOTICES);
  };

  useEffect(() => {
    setPageToDataLayer('New Notice');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
      <section>
        <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">New Notice</h1>
      </section>
      <section className="bg-white rounded-lg shadow p-6">
        <form className="w-full" onSubmit={handleSubmit(handleClickNewNotice, handleInvalidInputs)}>
          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="title">
              Title *
            </label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <TextField {...field} id="title" autoComplete="title" disabled={isLoading} />}
            />
          </section>

          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="content">
              Content *
            </label>
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <TextField {...field} id="content" autoComplete="content" disabled={isLoading} showCharacters />
              )}
            />
          </section>

          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="link-url">
              Link URL
            </label>
            <Controller
              name="linkUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="link-url"
                  autoComplete="link-url"
                  placeholder="https://"
                  disabled={isLoading}
                />
              )}
            />
          </section>

          <div>
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1">
              Posting period(KST) *
            </label>
          </div>
          <section className="mb-6 flex gap-4">
            <div className="w-1/2">
              <label
                className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
                htmlFor="announce-start-time"
              >
                Start
              </label>
              <Controller
                name="announceStartTime"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    selected={value}
                    onChange={onChange}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    showTimeSelect
                    timeFormat="HH:mm"
                    disabled={isLoading}
                    customInput={
                      <TextField
                        id="announce-start-time"
                        autoComplete="announce-start-time"
                        placeholder="YYYY-MM-DD HH:MM:SS"
                        disabled={isLoading}
                      />
                    }
                  />
                )}
              />
            </div>

            <div className="w-1/2">
              <label
                className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
                htmlFor="announce-expiry-time"
              >
                End
              </label>
              <Controller
                name="announceExpiryTime"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    selected={value}
                    onChange={onChange}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    showTimeSelect
                    timeFormat="HH:mm"
                    disabled={isLoading}
                    customInput={
                      <TextField
                        id="announce-expiry-time"
                        autoComplete="announce-expiry-time"
                        placeholder="YYYY-MM-DD HH:MM:SS"
                        disabled={isLoading}
                      />
                    }
                  />
                )}
              />
            </div>
          </section>

          <section className="mb-6 flex gap-4">
            <div className="w-1/2">
              <label
                className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
                htmlFor="cookie-life-time"
              >
                Cookie life time(hour) *
              </label>
              <Controller
                name="cookieLifeTime"
                control={control}
                render={({ field }) => (
                  <TextField {...field} id="cookie-life-time" autoComplete="cookie-life-time" disabled={isLoading} />
                )}
              />
            </div>
            <div className="w-1/2" />
          </section>

          <section className="mb-6 flex gap-4">
            <div className="w-1/2">
              <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="notice-type">
                Type *
              </label>
              <Controller
                name="noticeType"
                control={control}
                render={({ field }) => (
                  <SelectBox {...field} id="notice-type" autoComplete="notice-type" disabled={isLoading}>
                    <option value="release">Release</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="promotion">Promotion</option>
                    <option value="news">News</option>
                    <option value="etc">Etc</option>
                  </SelectBox>
                )}
              />
            </div>
            <div className="w-1/2" />
          </section>

          <section className="mb-6 flex gap-4">
            <div className="w-1/2">
              <label
                className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
                htmlFor="realease-version"
              >
                Release version
              </label>
              <Controller
                name="releaseVersion"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="realease-version"
                    autoComplete="realease-version"
                    placeholder="1.0.0"
                    disabled={isLoading}
                  />
                )}
              />
            </div>
            <div className="w-1/2" />
          </section>

          <hr className="mb-4" />

          <section className="flex justify-end gap-4">
            <Button color="primary" variant="outline" disabled={isLoading} onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button color="secondary" type="submit" disabled={isLoading}>
              Save
            </Button>
            {isLoading && <Spinner />}
          </section>
        </form>
      </section>
    </div>
  );
};
