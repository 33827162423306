import React from 'react';

type TextInputLabelProps = {
  htmlFor: string;
};

export const TextInputLabel: React.FC<TextInputLabelProps & React.PropsWithChildren> = ({ htmlFor, children }) => {
  return (
    <label className="block font-semibold text-sm text-darkGray mb-1" htmlFor={htmlFor}>
      {children}
    </label>
  );
};
