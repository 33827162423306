import BG from '@netspresso/components/assets/icons/bg-circle.svg';
import CostEffective from '@netspresso/components/assets/icons/cost_effective.svg';
import EnhancedPerformance from '@netspresso/components/assets/icons/enhanced_performance.svg';
import ImproveEfficiency from '@netspresso/components/assets/icons/improve_efficiency.svg';
import React from 'react';

type HeroProps = {
  handleLearnMore: VoidFunction;
};

export const Hero: React.FC<HeroProps> = ({ handleLearnMore }) => {
  return (
    <div className="relative overflow-hidden bg-bgblue-light">
      <div className="flex justify-center">
        <BG className="absolute bottom-0" />
      </div>
      <div className="relative h-full max-w-none lg:max-w-7xl mx-auto">
        <main className="max-w-none lg:max-w-7xl lg:px-32 lg:h-[590px] flex items-center">
          <div className="text-left pt-[77px] pb-5 lg:pt-[57px] px-10 w-full">
            <h1 className="text-3xl lg:text-5xl font-title font-bold text-sub leading-tight">
              The Hardware-aware AI Model
              <br />
              Optimization Platform
            </h1>
            <p className="mx-auto text-gray-900 font-title font-normal text-2xl lg:text-4xl leading-tight mt-6">
              Automatic search, compression, and deployment of models optimized for your target device.
            </p>
            <div className="max-w-md flex mt-8">
              <div className="rounded-md shadow-md">
                <button
                  type="button"
                  className="w-full flex items-center justify-center px-10 py-4 border border-transparent text-lg font-medium font-title rounded-md text-white bg-sub hover:bg-sub-600"
                  onClick={handleLearnMore}
                >
                  Learn More
                </button>
              </div>
            </div>
            <ul className="mt-12 flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0">
              <li className="flex flex-row items-center space-x-4">
                <CostEffective />
                <span className="font-title font-semibold text-lg text-main">Cost-effective AI</span>
              </li>
              <li className="flex flex-row items-center space-x-4">
                <EnhancedPerformance />
                <span className="font-title font-semibold text-lg text-main">
                  Enhanced
                  <br />
                  Model Performance
                </span>
              </li>
              <li className="flex flex-row items-center space-x-4">
                <ImproveEfficiency />
                <span className="font-title font-semibold text-lg text-main">
                  Improve development
                  <br />
                  efficiency
                </span>
              </li>
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
};
