import { FC, useEffect, useRef, useState } from 'react';
import { Heading } from '../Heading';

export enum DialogType {
  alert,
  prompt,
  confirm,
}

interface Prop {
  title?: string;
  type?: DialogType;
  width?: string;
  infoText?: string | React.ReactElement;
  promptId?: string;
  promptType?: string;
  promptTitle?: string;
  promptPlaceholder?: string;
  onClickConfirm: (value: string) => void;
  confirmDisabled?: boolean;
  confirmTitle?: string;
  cancelTitle?: string;
  onClickCancel?: () => void;
}

export const Dialog: FC<Prop> = ({
  title,
  width,
  onClickCancel,
  onClickConfirm,
  infoText,
  promptId,
  promptTitle,
  promptPlaceholder,
  confirmDisabled = false,
  promptType = 'text',
  cancelTitle = 'Cancel',
  confirmTitle = 'Confirm',
  type = DialogType.confirm,
}) => {
  const confirmButton = useRef<HTMLButtonElement>(null);
  const [promptInput, setPromptInput] = useState('');
  const handleChangePromptInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPromptInput(e.target.value);
  };
  const handleClickConfirm = () => {
    onClickConfirm(promptInput);
  };
  useEffect(() => {
    const button = confirmButton.current;
    if (button) {
      button.focus();
    }
  }, []);
  return (
    <div className={`flex flex-col justify-between bg-white rounded shadow-lg p-6 ${width}`}>
      {title && (
        <Heading element="h3" className="text-xl text-gray-900 font-title font-semibold mb-4">
          {title}
        </Heading>
      )}
      {infoText && <p className="text-sm text-gray-900 pb-4 whitespace-pre-line">{infoText}</p>}
      {type === DialogType.prompt && (
        <section className="mb-6">
          <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor={promptId}>
            {promptTitle}
          </label>
          <input
            className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
            type={promptType}
            id={promptId}
            name={promptId}
            placeholder={promptPlaceholder}
            onChange={handleChangePromptInput}
            value={promptInput}
          />
        </section>
      )}
      <div className="flex justify-end">
        {type !== DialogType.alert && (
          <button
            className="text-sm text-gray-700 hover:text-sub px-4 py-2 rounded font-medium leading-[14px] mr-2"
            onClick={onClickCancel}
          >
            {cancelTitle}
          </button>
        )}
        <button
          ref={confirmButton}
          className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-2 rounded font-medium leading-[14px] disabled:bg-disabledGray"
          disabled={confirmDisabled}
          onClick={handleClickConfirm}
        >
          {confirmTitle}
        </button>
      </div>
    </div>
  );
};
