import { Dialog, DialogType } from '@netspresso/components';
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import { Action } from '../../constants';
import { COMMON_ERROR_MESSAGE } from '../../constants/errors';
import { useAuthContext, useModalContext } from '../../contexts';

interface ProtectedRouteProps {
  children: ReactElement;
  type?: 'admin' | 'normal';
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, type = 'normal' }) => {
  const { user, isSignedIn } = useAuthContext();

  const [, dispatchModal] = useModalContext();

  const handleAccessAuthPage = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Page access denied"
          infoText={`${COMMON_ERROR_MESSAGE[401]}`}
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleAccessAdminPage = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Page access denied"
          infoText={`${COMMON_ERROR_MESSAGE[403]}`}
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  if (user?.is_admin) {
    return children;
  }

  if (isSignedIn) {
    if (type === 'admin') {
      handleAccessAuthPage();

      return <Navigate to="/signin" />;
    }

    return children;
  }
  handleAccessAdminPage();

  return <Navigate to="/signin" />;
};
