import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { CookieConsent } from './components/Portal/components';
import { AuthContextProvider, Compose, ModalContextProvider, UserAgentFormContextProvider } from './contexts';
import { AgentStepContextProvider } from './contexts/AgentStepContext';
import './index.css';
import './library/i18n/i18n.ts';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnReconnect: false, retry: false },
  },
});

const providers = [AuthContextProvider, ModalContextProvider, UserAgentFormContextProvider, AgentStepContextProvider];

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Compose providers={providers}>
        <App />
        <CookieConsent />
      </Compose>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
