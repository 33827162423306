import { ClientWithAuth, ClientWithoutAuth } from './Clients';
import { UserAuthorities, UserRegion } from './UserService';

export interface SigninRequest {
  username: string;
  password: string;
}
export interface SigninResponse {
  current_time: string;
  is_reset: 0 | 1;
  region: UserRegion;
  tokens: {
    access_token: string;
    refresh_token: string;
  };
}
export interface CreateAccessTokenRequest {
  accessToken: string;
  refreshToken: string;
}
export interface CreateAccessTokenResponse {
  tokens: {
    access_token: string;
    refresh_token: string;
  };
}
export interface ReadTokenValidationResponse {
  user_id: string;
  authorities: UserAuthorities;
  region: UserRegion;
  exp: number;
  current_time: string;
}

export const AuthService = (() => {
  const signIn = ({ username, password }: SigninRequest) => {
    const payload = { username, password };

    return ClientWithoutAuth.post<SigninResponse>('/auth/local/login', payload);
  };
  const createAccessToken = ({ accessToken, refreshToken }: CreateAccessTokenRequest) => {
    const payload = { access_token: accessToken, refresh_token: refreshToken };

    return ClientWithoutAuth.post<CreateAccessTokenResponse>('/auth/token', payload);
  };
  const readTokenValidation = () => {
    return ClientWithAuth.get<ReadTokenValidationResponse>('/auth/token');
  };

  return { signIn, createAccessToken, readTokenValidation };
})();
