/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

interface Props {
  value?: boolean;
  onClick?: () => void;
}

export const Toggle: React.FC<Props> = ({ value = false, onClick }) => {
  return (
    <div className="relative inline-block align-middle select-none text-left">
      <input className="hidden" type="checkbox" />
      <div
        className={`transition ease-in-out duration-200 w-12 h-6 rounded-full flex items-center justify-items-start my-1 ${
          value ? 'bg-sub' : 'bg-defaultGray'
        } cursor-pointer`}
        onClick={onClick}
      >
        <div className={`w-[18px] h-[18px] ${value ? 'ml-auto mr-1' : 'ml-1'} rounded-full bg-white`} />
      </div>
    </div>
  );
};
