import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { Route, Routes } from 'react-router-dom';
import { Layout, ModalContainer } from './components/Portal';
import { LoadingBox } from './components/Portal/components/LoadingBox/LoadingBox';
import { ProtectedRoute } from './components/ProtectedRoute';
import {
  ACCOUNT_SETTINGS,
  NEW_NOTICE,
  NEW_PASSWORD,
  NOTICES,
  PERSONAL_SERVER,
  RESOURCES,
  SIGN_IN,
  SIGN_UP,
  UPDATE_ACCOUNT,
  UPDATE_NOTICE,
} from './constants';
import { useAuthContext, useModalContext } from './contexts';

import './css/styles.css';
import { Main, NewPassword, Signin, Signup, UpdateAccount } from './domain';
import { PersonalServer, Resources, Settings } from './domain/Account';
import { NewNotice, Notices, UpdateNotice } from './domain/Admin';

function App() {
  const { isRefreshed, isLoading } = useAuthContext();
  const [{ showModal, children }] = useModalContext();

  if (isRefreshed || isLoading) {
    return <LoadingBox fullScreen />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          {flag(FEATURE_FLAGS.Account) && (
            <Route
              path={ACCOUNT_SETTINGS}
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path={RESOURCES}
            element={
              <ProtectedRoute>
                <Resources />
              </ProtectedRoute>
            }
          />
          <Route
            path={PERSONAL_SERVER}
            element={
              <ProtectedRoute>
                <PersonalServer />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PERSONAL_SERVER}/:uuid`}
            element={
              <ProtectedRoute>
                <PersonalServer />
              </ProtectedRoute>
            }
          />
          <Route
            path={NOTICES}
            element={
              <ProtectedRoute type="admin">
                <Notices />
              </ProtectedRoute>
            }
          />
          <Route
            path={NEW_NOTICE}
            element={
              <ProtectedRoute type="admin">
                <NewNotice />
              </ProtectedRoute>
            }
          />
          <Route
            path={UPDATE_NOTICE}
            element={
              <ProtectedRoute type="admin">
                <UpdateNotice />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={UPDATE_ACCOUNT}
          element={
            <ProtectedRoute>
              <UpdateAccount />
            </ProtectedRoute>
          }
        />
        <Route path={SIGN_IN} element={<Signin />} />
        {flag(FEATURE_FLAGS.SignUp) && <Route path={SIGN_UP} element={<Signup />} />}
        <Route path={NEW_PASSWORD} element={<NewPassword />} />
      </Routes>
      <ModalContainer showModal={showModal}>{children}</ModalContainer>
    </>
  );
}

export default App;
