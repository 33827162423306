import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { UserAgentFormDefaultValues, UserAgentFormScheme, UserAgentFormType } from '../../schemas';

export const UserAgentFormContext = React.createContext<UseFormReturn<UserAgentFormType> | null>(null);

export const UserAgentFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const userAgentForm = useForm<UserAgentFormType>({
    defaultValues: UserAgentFormDefaultValues,
    mode: 'onBlur',
    resolver: zodResolver(UserAgentFormScheme),
  });

  return <UserAgentFormContext.Provider value={userAgentForm}>{children}</UserAgentFormContext.Provider>;
};

export const useUserAgentFormContext = (): UseFormReturn<UserAgentFormType> => {
  const context = React.useContext(UserAgentFormContext);

  if (!context) {
    throw new Error('useUserAgentFormContext must be used within a UserAgentFormContextProvider');
  }

  return context;
};
