import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Dialog } from '@netspresso/components';
import Hamburger from '@netspresso/components/assets/icons/hamburger.svg';
import MobileClose from '@netspresso/components/assets/icons/mobile_close.svg';
import NetsPressoLogo from '@netspresso/components/assets/logos/logo.svg';
import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import {
  ACCOUNT_SETTINGS,
  Action,
  COMMUNITY_LINK,
  CREDIT_LINK,
  DOC_LINK,
  NOTICES,
  PYNETSPRESSO_LINK,
  RESOURCES,
  SIGN_IN,
  SIGN_UP,
} from '../../../../constants';
import { useAuthContext, useModalContext } from '../../../../contexts';
import { GoogleTagManager } from '../../../../library/utils';
import { AccountPanel, StartPanel } from './components';

export const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, dispatchModal] = useModalContext();
  const { user, isSignedIn, signOut } = useAuthContext();
  const [showStartMenu, setShowStartMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  const onStartMouseEnter = () => {
    setShowStartMenu(true);
  };

  const onStartMouseLeave = () => {
    setShowStartMenu(false);
  };

  const onAccountMouseEnter = () => {
    setShowAccountMenu(true);
  };

  const onAccountMouseLeave = () => {
    setShowAccountMenu(false);
  };

  const onClickBurger = () => {
    const showMenu = !showMobileMenu;

    setShowMobileMenu(showMenu);
  };

  const onClickConfirm = () => {
    signOut();
    dispatchModal({ type: Action.HIDE });
  };

  const onClickCancel = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const onClickSignout = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          title="Are you sure you want to sign out?"
          onClickConfirm={onClickConfirm}
          onClickCancel={onClickCancel}
        />
      ),
    });
  };

  useEffect(() => {
    if (user) {
      const isAgreed = GoogleTagManager.isAgreed();

      if (isAgreed) {
        GoogleTagManager.dataLayer({
          dataLayer: {
            user_id: user.user_id,
          },
        });
      }
    }
  }, [user]);

  return (
    <nav className="absolute top-0 lg:relative w-full z-10 bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto pl-8 pr-4 lg:px-8">
        <div className="flex justify-between h-[56px]">
          <div className="flex items-center">
            <div className="shrink-0 flex items-center mr-6">
              <Link to="/">
                <NetsPressoLogo />
              </Link>
            </div>
            {isSignedIn && (
              <div
                className="relative mr-4 hidden lg:block"
                onMouseEnter={onStartMouseEnter}
                onMouseLeave={onStartMouseLeave}
              >
                <button
                  type="button"
                  className="flex flex-row items-center justify-between bg-sub hover:bg-sub-600 text-white font-subtitle font-semibold pl-4 pr-2 rounded py-1"
                >
                  <span className="mr-2">Start</span>
                  <span className="material-icons-outlined text-lg">expand_more</span>
                </button>
                {showStartMenu && <StartPanel />}
              </div>
            )}
            <div className="h-full hidden lg:block">
              <a
                href={DOC_LINK}
                className="h-full border-transparent text-gray-900 hover:border-sub inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium mr-4"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
              <a
                href={COMMUNITY_LINK}
                className="h-full border-transparent text-gray-900 hover:border-sub inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium mr-4"
                target="_blank"
                rel="noreferrer"
              >
                Discussion Forum
              </a>
              <a
                href={PYNETSPRESSO_LINK}
                className="h-full border-transparent text-gray-900 hover:border-sub inline-flex gap-1 items-center px-1 pt-1 border-b-2 text-sm font-medium mr-4"
                target="_blank"
                rel="noreferrer"
              >
                PyNetsPresso <span className="material-icons-outlined text-base pt-px">open_in_new</span>
              </a>
              <a
                href={process.env.REACT_APP_LAUNCHX_URL}
                className="h-full border-transparent text-gray-900 hover:border-sub inline-flex gap-1 items-center px-1 pt-1 border-b-2 text-sm font-medium mr-4"
                target="_blank"
                rel="noreferrer"
              >
                LaunchX <span className="material-icons-outlined text-base pt-px">open_in_new</span>
              </a>
              {user && user.is_admin === 1 && (
                <Link
                  to={NOTICES}
                  className="h-full border-transparent text-gray-900 hover:border-sub inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium mr-4"
                >
                  Admin
                </Link>
              )}
            </div>
          </div>

          {isSignedIn ? (
            <div
              className="hidden lg:flex items-center"
              onMouseEnter={onAccountMouseEnter}
              onMouseLeave={onAccountMouseLeave}
            >
              <div className="relative">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between font-subtitle px-2 rounded py-1 hover:text-sub"
                >
                  <span className="mr-1">{user?.username}</span>
                  <span className="material-icons-outlined text-lg">expand_more</span>
                </button>
                {showAccountMenu && <AccountPanel />}
              </div>
            </div>
          ) : (
            <div className="hidden lg:flex items-center">
              <Link to={SIGN_IN} className="hover:text-sub">
                Sign in
              </Link>
              {flag(FEATURE_FLAGS.SignUp) && (
                <div className="ml-4">
                  <button
                    type="button"
                    className="p-1 px-3 bg-transparent border border-sub text-sub rounded hover:bg-sub-600 hover:text-white"
                    onClick={() => {
                      navigate(SIGN_UP);
                    }}
                  >
                    Sign up
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="flex items-center lg:hidden">
            <button
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sub"
              type="button"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={onClickBurger}
            >
              <span className="sr-only">Open main menu</span>
              {showMobileMenu ? <MobileClose /> : <Hamburger />}
            </button>
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div className="lg:hidden" id="mobile-menu">
          {isSignedIn ? (
            <>
              <div className="space-y-1 pb-3">
                <a
                  href={`${process.env.REACT_APP_SEARCHER_URL}/models`}
                  className="relative block py-2 pl-3 pr-4 text-base font-medium text-gray-600"
                >
                  <div className="absolute">
                    <span className="material-icons-outlined text-sub">saved_search</span>
                  </div>
                  <p className="ml-8 text-base font-medium text-gray-900">Model Searcher</p>
                </a>
                <a
                  href={
                    flag(FEATURE_FLAGS.CombinedCompression)
                      ? `${process.env.REACT_APP_SEARCHER_URL}/models`
                      : `${process.env.REACT_APP_COMPRESSOR_URL}/models`
                  }
                  className="relative block py-2 pl-3 pr-4 text-base font-medium text-gray-600"
                >
                  <div className="absolute">
                    <span className="material-icons-outlined text-sub">compress</span>
                  </div>
                  <p className="ml-8 text-base font-medium text-gray-900">Model Compressor</p>
                </a>
                <a
                  href={`${process.env.REACT_APP_SEARCHER_URL}/models`}
                  className="relative block py-2 pl-3 pr-4 text-base font-medium text-gray-600"
                >
                  <div className="absolute">
                    <span className="material-icons text-sub">rocket_launch</span>
                  </div>
                  <p className="ml-8 text-base font-medium text-gray-900">Model Launcher</p>
                </a>
                <div className="border-t border-gray-200 pt-2">
                  <a
                    href={DOC_LINK}
                    className="block px-4 py-2 text-sm text-gray-900 font-medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                  <a
                    href={COMMUNITY_LINK}
                    className="block px-4 py-2 text-sm text-gray-900 font-medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discussion Forum
                  </a>
                  <a
                    href={PYNETSPRESSO_LINK}
                    className="flex gap-1 px-4 py-2 text-sm text-gray-900 font-medium items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PyNetsPresso <span className="material-icons-outlined text-base">open_in_new</span>
                  </a>
                  <a
                    href={process.env.REACT_APP_LAUNCHX_URL}
                    className="flex gap-1 px-4 py-2 text-sm text-gray-900 font-medium items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LaunchX <span className="material-icons-outlined text-base">open_in_new</span>
                  </a>
                  {flag(FEATURE_FLAGS.Account) && isSignedIn && (
                    <Link to={ACCOUNT_SETTINGS} className="block px-4 py-2 text-sm text-gray-900 font-medium">
                      My Account
                    </Link>
                  )}
                  {user && user.is_admin === 1 && (
                    <Link to={NOTICES} className="block px-4 py-2 text-sm text-gray-900 font-medium">
                      Admin
                    </Link>
                  )}
                </div>
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3 divide-y divide-gray-100">
                <div className="flex items-center px-4">{user?.username}</div>
                <div className="mt-3 space-y-1 divide-y divide-gray-100">
                  <div className="py-2">
                    <a
                      href={CREDIT_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-700 block px-4 py-2 text-sm hover:text-sub"
                      tabIndex={-1}
                    >
                      Credit <span className="text-sm font-medium text-gray-900 truncate">{user?.credit}</span>
                    </a>
                  </div>
                  <div className="py-2">
                    {flag(FEATURE_FLAGS.Account) && (
                      <Link
                        to={ACCOUNT_SETTINGS}
                        className="text-gray-700 block px-4 py-2 text-sm hover:text-sub"
                        tabIndex={-1}
                      >
                        Account settings
                      </Link>
                    )}
                    <Link to={RESOURCES} className="text-gray-700 block px-4 py-2 text-sm hover:text-sub" tabIndex={-1}>
                      Resources
                    </Link>
                  </div>
                  <div className="pt-2 pb-1">
                    <button
                      type="button"
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:text-sub"
                      role="menuitem"
                      tabIndex={-1}
                      onClick={onClickSignout}
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pb-3">
                <a
                  href={DOC_LINK}
                  className="block px-8 py-2 text-sm text-gray-900 font-medium"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
                <a
                  href={COMMUNITY_LINK}
                  className="block px-8 py-2 text-sm text-gray-900 font-medium"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discussion Forum
                </a>
                <a
                  href={PYNETSPRESSO_LINK}
                  className="block px-8 py-2 text-sm text-gray-900 font-medium"
                  target="_blank"
                  rel="noreferrer"
                >
                  PyNetsPresso <span className="material-icons-outlined text-base">open_in_new</span>
                </a>
                <a
                  href={process.env.REACT_APP_LAUNCHX_URL}
                  className="block px-8 py-2 text-sm text-gray-900 font-medium"
                  target="_blank"
                  rel="noreferrer"
                >
                  LaunchX <span className="material-icons-outlined text-base">open_in_new</span>
                </a>
              </div>
              <div className="border-t border-gray-200 py-4">
                <div>
                  <Link to={SIGN_IN} className="text-gray-900 block w-full text-left px-8 py-2 font-medium text-sm">
                    Sign in
                  </Link>
                  {flag(FEATURE_FLAGS.SignUp) && (
                    <Link to={SIGN_UP} className="text-sub block w-full text-left px-8 py-2 font-medium text-sm">
                      Sign up
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </nav>
  );
};
