import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ModulesTabContents, ModulesTabNavigator, TabIndex } from '../../components/Portal';
import { useAuthContext } from '../../contexts';
import { Hero, NewsList, PyNetsPressoBanner } from './components';

export const Main = () => {
  const learnMoreRef = useRef<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<TabIndex>(0);

  const { isSignedIn } = useAuthContext();

  const sendToLearnmore = () => {
    learnMoreRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClickSignup = () => {
    navigate('/signup');
  };

  const handleClickGetStarted = () => {
    window.open(`${process.env.REACT_APP_SEARCHER_URL}/models`);
  };

  return (
    <>
      <Hero handleLearnMore={sendToLearnmore} />
      {flag(FEATURE_FLAGS.ShowBanner) ? <PyNetsPressoBanner /> : <NewsList />}
      <section className="bg-notaNavy-700 flex justify-center" ref={learnMoreRef}>
        <img
          className="hidden lg:block"
          src="/images/modules.png"
          alt="One stop shop for optimized AI model development"
        />
        <img
          className="lg:hidden block"
          src="/images/modules_mobile.png"
          alt="One stop shop for optimized AI model development"
        />
      </section>
      <section className="bg-white lg:py-10 pb-10">
        <ModulesTabNavigator selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <ModulesTabContents selectedTab={selectedTab} />
      </section>
      <section className="bg-gray-100 flex justify-center">
        {selectedTab === 0 ? (
          <img src="/images/searcher_pipeline.png" alt="Model Searcher" />
        ) : selectedTab === 1 ? (
          <img src="/images/compressor_pipeline.png" alt="Model Compressor" />
        ) : (
          <img src="/images/launcher_pipeline.png" alt="Model Launcher" />
        )}
      </section>
      <section className="relative bg-sub flex items-center justify-center font-title p-8">
        <div className="lg:absolute">
          <h1 className="text-white text-3xl lg:w-[894px]">The Hardware-aware AI Model Optimization Platform</h1>
          <div className="mt-8">
            {isSignedIn ? (
              <button
                type="button"
                onClick={handleClickGetStarted}
                className="py-3 px-8 bg-point hover:bg-subpoint text-notaNavy-700 font-semibold rounded-lg"
              >
                Get Started
              </button>
            ) : (
              flag(FEATURE_FLAGS.SignUp) && (
                <button
                  type="button"
                  onClick={handleClickSignup}
                  className="py-3 px-8 bg-point hover:bg-subpoint text-notaNavy-700 font-semibold rounded-lg"
                >
                  Sign up
                </button>
              )
            )}
            <a href="mailto:netspresso@nota.ai" className="py-3 px-8 text-white hover:text-subpoint font-semibold">
              Book a Demo
            </a>
          </div>
        </div>
        <img
          className="hidden lg:block"
          src="/images/bg-bottom.png"
          alt="The Hardware-aware AI Model Optimization Platform background"
        />
      </section>
    </>
  );
};
