import { ServerStatus } from '../../../lib/agent';

export const parseServerColor = (status: ServerStatus) => {
  switch (status) {
    case 'not_available':
      return 'text-red-600';
    case 'running':
      return 'text-blue-600';
    case 'available':
      return 'text-green-600';
  }

  return 'text-gray-900';
};
