import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, Dialog, DialogType, Spinner } from '@netspresso/components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Action } from '../../../../constants';
import {
  ALPHABET_ONLY_FIRST_NAME,
  ALPHABET_ONLY_LAST_NAME,
  NEED_PASSWORD,
  TOO_LONG_FIRST_NAME,
  TOO_LONG_LAST_NAME,
  TOO_SHORT_FIRST_NAME,
  TOO_SHORT_LAST_NAME,
} from '../../../../constants/errors';
import { REGION } from '../../../../constants/labels';
import { useAuthContext, useModalContext } from '../../../../contexts';
import { User, UserService } from '../../../../library/apis/UserService';

const UpdateUserDefaultValues = {
  firstName: '',
  lastName: '',
  company: '',
  marketingAgree: false,
  currentPassword: '',
};

// todo: 다른파일로이동
const UpdateUserSchema = z.object({
  firstName: z
    .string()
    .min(2, TOO_SHORT_FIRST_NAME)
    .max(40, TOO_LONG_FIRST_NAME)
    .regex(/^[a-zA-Z]/, ALPHABET_ONLY_FIRST_NAME),
  lastName: z
    .string()
    .min(2, TOO_SHORT_LAST_NAME)
    .max(40, TOO_LONG_LAST_NAME)
    .regex(/^[a-zA-Z]/, ALPHABET_ONLY_LAST_NAME),
  company: z.string(),
  marketingAgree: z.boolean(),
  currentPassword: z.string().min(1, NEED_PASSWORD),
});

type UpdateUserType = z.infer<typeof UpdateUserSchema>;

export const UpdateUser = () => {
  const [, dispatchModal] = useModalContext();

  const { user } = useAuthContext();

  const { register, control, handleSubmit, setValue } = useForm({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: UpdateUserDefaultValues,
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', user.detail_data.first_name);
      setValue('lastName', user.detail_data.last_name);
      setValue('company', user.detail_data.company);
      setValue('marketingAgree', !!user.detail_data.marketing_agreement);
    }
  }, [setValue, user]);

  const handleUpdateUserSuccess = (_: AxiosResponse<User>) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Change account settings"
          infoText="Your changes have been saved."
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
  };

  const handleUpdateUserError = (_: AxiosError) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Change account settings"
          infoText="Please check your current password."
          width="w-[600px]"
          onClickConfirm={handleClickConfirm}
        />
      ),
    });
  };

  const { mutate, isLoading } = useMutation(UserService.updateUser, {
    onSuccess: handleUpdateUserSuccess,
    onError: handleUpdateUserError,
  });

  const handleClickUpdateUser: SubmitHandler<UpdateUserType> = ({
    firstName,
    lastName,
    company,
    marketingAgree,
    currentPassword,
  }) => {
    mutate({
      firstName,
      lastName,
      company,
      marketingAgree,
      currentPassword,
    });
  };

  const handleInvalidInputs: SubmitErrorHandler<UpdateUserType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickConfirm = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const isDisabled = isLoading;

  return (
    <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">Account</h1>
      <section className="bg-white rounded-lg shadow p-6">
        <form className="w-full" onSubmit={handleSubmit(handleClickUpdateUser, handleInvalidInputs)}>
          <section className="flex flex-row mb-6">
            <div className="w-1/2 pr-2">
              <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1">ID (Email)</label>
              <p>{user?.email}</p>
            </div>
            <div className="w-1/2 pl-2">
              <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1">Access region</label>
              <p>{REGION[user?.region || '']}</p>
            </div>
          </section>
          <section className="flex flex-row mb-6">
            <div className="w-1/2 pr-2">
              <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="first-name">
                First name *
              </label>
              <input
                {...register('firstName')}
                className="block w-full border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary"
                type="text"
                id="first-name"
                name="first-name"
                placeholder="First name"
                disabled={isDisabled}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="last-name">
                Last name *
              </label>
              <input
                {...register('lastName')}
                className="block w-full border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary"
                type="text"
                id="last-name"
                name="last-name"
                placeholder="Last name"
                disabled={isDisabled}
              />
            </div>
          </section>
          <section className="mb-6">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1" htmlFor="company">
              Company
            </label>
            <input
              {...register('company')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="text"
              id="company"
              name="company"
              placeholder="Please insert your company name"
              disabled={isDisabled}
            />
          </section>
          <section className="mb-4">
            <label className="block font-subtitle font-semibold text-sm text-gray-700 mb-1">Marketing agreement</label>
            <div className="flex flex-row items-center">
              <Controller
                name="marketingAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} id="marketing-agree" className="mt-1" disabled={isDisabled} />
                )}
              />
              <label className="ml-2 -mt-0.5 text-regular text-gray-700" htmlFor="marketing-agree">
                I want to receive communications or be contacted by Nota about related technology.
              </label>
            </div>
          </section>
          <hr className="mb-4" />
          <section className="mb-6">
            <label
              className="block font-subtitle font-semibold text-sm text-gray-700 mb-1"
              htmlFor="update-user-current-password"
            >
              Current password *
            </label>
            <input
              {...register('currentPassword')}
              className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
              type="password"
              id="update-user-current-password"
              autoComplete="current-password"
              placeholder="Please insert your current password"
              disabled={isDisabled}
            />
          </section>
          <section className="flex gap-4">
            <Button color="secondary" type="submit" disabled={isDisabled}>
              Save
            </Button>
            {isDisabled && <Spinner />}
          </section>
        </form>
      </section>
    </div>
  );
};
