import { Button, Heading } from '@netspresso/components';
import { useTranslation } from 'react-i18next';
import { NotionViewer } from '../../../components/Portal/components/NotionViewer';

interface Props {
  handleClickModalConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  handleClickModalCancel?: React.MouseEventHandler<HTMLButtonElement>;
  handleClickModalClose?: React.MouseEventHandler<HTMLButtonElement>;
}

export const TermsAgree = ({ handleClickModalConfirm, handleClickModalCancel, handleClickModalClose }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="h-[80%] w-[80%] max-w-[1200px] p-8 flex flex-col gap-4 bg-white rounded-lg shadow">
        <div className="h-16">
          <Heading className="text-2xl mt-2 text-gray-900 text-center">{t(`TERMS_AGREE.TITLE`)}</Heading>
        </div>
        <div className="flex-grow overflow-y-scroll rounded-lg p-4 border">
          <NotionViewer pageId={'7a44a40341494380a82c9bdb940f0a20'} />
        </div>
        <div className="flex flex-col gap-4">
          {handleClickModalConfirm && (
            <Button onClick={handleClickModalConfirm} value="termsAgree" size="full" color="secondary" shadow>
              {t(`TERMS_AGREE.AGREE_BUTTON`)}
            </Button>
          )}
          {handleClickModalCancel && (
            <Button onClick={handleClickModalCancel} value="termsAgree" size="full" variant="outline" color="secondary">
              {t(`TERMS_AGREE.DISAGREE_BUTTON`)}
            </Button>
          )}
          {handleClickModalClose && (
            <Button onClick={handleClickModalClose} size="full" variant="outline" color="secondary">
              {t(`TERMS_AGREE.CLOSE_BUTTON`)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
