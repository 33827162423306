export const CONTACT_LINK = 'mailto:netspresso@nota.ai';
export const DOC_LINK = 'https://docs.netspresso.ai/';
export const COMMUNITY_LINK = 'https://github.com/Nota-NetsPresso/Discussion/discussions';
export const CREDIT_LINK = 'https://docs.netspresso.ai/docs/credit';
export const REQUIREMENTS_LINK = 'https://docs.netspresso.ai/docs/requirements';
export const CONNECT_SERVER_LINK = 'https://docs.netspresso.ai/docs/connect-server';
export const RELEASE_NOTES_LINK = [
  {
    label: 'NetsPresso 1.5.0 - Nov 7, 2023',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---150-november-7-2023',
  },
  {
    label: 'NetsPresso 1.4.0 - Sep 21, 2023',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---150-november-7-2023',
  },
  {
    label: 'NetsPresso 1.3.0 - May 18, 2023',
    url: 'https://docs.netspresso.ai/v1.3.0/docs/release-notes#netspresso-release---130-may-18-2023',
  },
  {
    label: 'NetsPresso 1.2.2 - Apr 21, 2023',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---122-april-21-2023',
  },
  {
    label: 'NetsPresso 1.2.1 - Feb 9, 2023',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---121-february-9-2023',
  },
  {
    label: 'NetsPresso 1.2.0 - Dec 16, 2022',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---120-december-16-2022',
  },
  {
    label: 'NetsPresso 1.1.2 - Nov 29, 2022',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---112-november-29-2022',
  },
  {
    label: 'NetsPresso 1.1.0 - Oct 28, 2022',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---110-october-28-2022',
  },
  {
    label: 'NetsPresso 1.0.1 - Sep 28, 2022',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---101-september-28-2022',
  },
  {
    label: 'NetsPresso 1.0.0 - Aug 30, 2022',
    url: 'https://docs.netspresso.ai/docs/release-notes#netspresso-release---100-august-30-2022',
  },
];
export const ITS_LINK = 'https://www.nota.ai/its';
export const DMS_LINK = 'https://www.nota.ai/dms';
export const PYNETSPRESSO_LINK = 'https://netspresso.ai';
export const PYNETSPRESSO_BANNER_LINK =
  'https://netspresso.ai';
export const LAUNCHX_LINK =
  'https://launchx.netspresso.ai/main?utm_source=np_portal&utm_medium=top_menu&utm_campaign=lx_launch';
export const DOWNLOAD_FREE_MODELS_LINK =
  'https://github.com/Nota-NetsPresso/Discussion/discussions/categories/netspresso-free-models';
export const SEARCHER_DEMO_VIDEO_LINK = 'https://www.youtube.com/watch?v=SmQdFlNpzlk&feature=youtu.be';
export const COMPRESSOR_DEMO_VIDEO_LINK = 'https://www.youtube.com/watch?v=mNc2DnRrqhU';
export const SEARCHER_BENCHMARKS_LINK = 'https://docs.netspresso.ai/docs/performance-benchmark';
export const COMPRESSOR_BEST_PRACTICE_LINK = 'https://docs.netspresso.ai/docs/best-practices';
export const TERMS_OF_SERVICE_LINK = 'https://www.google.com/';
export const PRIVACY_POLICY_LINK = 'https://www.google.com/';
export const NOTA_LINK = 'https://www.nota.ai/';
export const FACEBOOK_LINK = 'https://www.facebook.com/NotaAI';
export const TWITTER_LINK = 'https://twitter.com/nota_ai';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCeewYFAqb2EqwEXZCfH9DVQ';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/nota-incorporated';
export const VALIDATION_GUIDE_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-Model-Searcher-Dataset-Validator';
