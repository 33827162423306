import { FEATURE_FLAGS, flag } from '@netspresso/shared';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNT_SETTINGS, RESOURCES } from '../../../constants';
import { useGTM } from '../../../hooks';
import { MyPlan, NetspressoServer, PersonalServers } from './components';

export const Resources = () => {
  const { setPageToDataLayer } = useGTM();

  useEffect(() => {
    setPageToDataLayer('Resources');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative overflow-hidden">
      <section className="w-full border-b border-lineGray bg-white">
        <div className="max-w-7xl mx-auto px-8">
          <ul className="flex flex-row">
            {flag(FEATURE_FLAGS.Account) && (
              <li>
                <Link className="inline-block font-title font-bold text-gray-400 px-3 pt-2 pb-2" to={ACCOUNT_SETTINGS}>
                  Account Settings
                </Link>
              </li>
            )}
            <li>
              <Link
                className="inline-block font-title font-bold text-gray-700 px-3 pt-2 pb-2 border-b-2 border-subpoint"
                to={RESOURCES}
              >
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="w-full">
        <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
          <MyPlan />
          <NetspressoServer />
          <PersonalServers />
        </div>
      </section>
    </div>
  );
};
