import { FC } from 'react';
import { TabIndex } from '../ModulesTabNavigator';
import { Compressor, Launcher, Searcher } from './components';

interface Props {
  selectedTab: TabIndex;
}

export const ModulesTabContents: FC<Props> = ({ selectedTab }) => {
  return <>{selectedTab === 0 ? <Searcher /> : selectedTab === 1 ? <Compressor /> : <Launcher />}</>;
};
