/* eslint-disable camelcase */
import { Dialog, DialogType, Spinner } from '@netspresso/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Pagenation } from '../../../components/Portal';
import { Action, NEW_NOTICE, REACT_QUERY, UPDATE_NOTICE } from '../../../constants';
import { COMMON_ERROR_MESSAGE } from '../../../constants/errors';
import { useModalContext } from '../../../contexts';
import { useGTM } from '../../../hooks';
import { NoticeService } from '../../../library/apis';
import { Time } from '../../../library/utils';

export const Notices = () => {
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const [, dispatchModal] = useModalContext();

  const [pagenation, setPagenation] = useState({
    pageOffset: 0,
    pageSize: 10,
    totalCount: 0,
    totalPages: 1,
  });

  const { data, isFetched, remove } = useQuery(
    [REACT_QUERY.NOTICES, pagenation.pageOffset, pagenation.pageSize],
    () => NoticeService.readNotices({ pageOffset: pagenation.pageOffset, pageSize: pagenation.pageSize }),
    {
      onSuccess: ({ data: { total_count, total_pages } }) => {
        setPagenation({
          ...pagenation,
          totalCount: total_count,
          totalPages: total_pages,
        });
      },
    }
  );

  const handleDeleteNoticeSuccess = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Delete notice success"
          infoText="Notice deleted successfully."
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
    remove();
  };

  const handleDeleteNoticeError = (error: AxiosError) => {
    const errorCode = error.code || '';

    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Delete notice failed"
          infoText={`Error while deleting notice.\n${COMMON_ERROR_MESSAGE[errorCode || '']}`}
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const { mutate } = useMutation(NoticeService.deleteNotice, {
    onSuccess: handleDeleteNoticeSuccess,
    onError: handleDeleteNoticeError,
  });

  const handleClickNewNotice = () => {
    navigate(NEW_NOTICE);
  };

  const handleClickEdit = (noticeId: string) => {
    navigate(UPDATE_NOTICE.replace(':noticeId', noticeId));
  };

  const handleClickDelete = (noticeId: string) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.confirm}
          title="Delete notice"
          infoText={`Notice(${noticeId}) will be deleted.`}
          width="w-[600px]"
          onClickConfirm={() => handleClickDeleteConfirm(noticeId)}
          onClickCancel={handleClickDeleteCancel}
        />
      ),
    });
  };

  const handleClickDeleteConfirm = (noticeId: string) => {
    mutate({ noticeId });
    dispatchModal({ type: Action.HIDE });
  };

  const handleClickDeleteCancel = () => {
    dispatchModal({ type: Action.HIDE });
  };

  const handleClickPage = (pageNumber: number) => {
    setPagenation({ ...pagenation, pageOffset: pageNumber });
    remove();
  };

  useEffect(() => {
    setPageToDataLayer('Notices');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="w-full">
      <div className="max-w-7xl mx-auto py-6 pt-4 px-8">
        <section className="flex flex-row justify-between mb-4">
          <h1 className="text-main text-xl font-title font-bold pl-3">Notices</h1>
          <button
            onClick={handleClickNewNotice}
            type="button"
            className="bg-sub hover:bg-sub-600 disabled:bg-disabledGray text-white text-sm leading-none px-2 pr-3 py-1 rounded shadow"
          >
            <span className="material-icons mr-2 mt-0.5">add</span>
            <span className="inline-block font-medium leading-7 align-top">New Notice</span>
          </button>
        </section>
        <section className="bg-white rounded-lg shadow mb-10">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="py-3 pl-6 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  ID
                </th>
                <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  Title
                </th>
                <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  Posting Period (KST)
                </th>
                <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  Created
                </th>
                <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  Updated
                </th>
                <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                  Total: {pagenation.totalCount}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {!isFetched && (
                <tr>
                  <td colSpan={6}>
                    <div className="h-24 flex justify-center items-center">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
              {isFetched &&
                data?.data.data.map((notice) => {
                  return (
                    <tr key={notice.id} className="font-normal">
                      <td className="py-4 pl-6 text-sm text-gray-900">
                        <p className="text-gray-900">{notice.id}</p>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        <p className="text-gray-900 text-ellipsis whitespace-nowrap overflow-hidden">{notice.title}</p>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        <p className="text-gray-900">
                          {Time.UTC2KSTString(notice.announce_start_time)} ~{' '}
                          {Time.UTC2KSTString(notice.announce_expiry_time)}
                        </p>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        <p className="text-gray-900">{Time.UTC2KSTString(notice.created_time)}</p>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        <p className="text-gray-900">{Time.UTC2KSTString(notice.updated_time)}</p>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        <div className="flex flex-row gap-2">
                          <button
                            onClick={() => {
                              handleClickEdit(notice.notice_id);
                            }}
                            type="button"
                            className="w-8 h-8 flex justify-center items-center bg-transparent border border-sub text-sub rounded hover:bg-sub-600 hover:text-white"
                          >
                            <span className="material-icons text-lg">edit</span>
                          </button>
                          <button
                            onClick={() => {
                              handleClickDelete(notice.notice_id);
                            }}
                            type="button"
                            className="w-8 h-8 flex justify-center items-center bg-transparent border border-sub text-sub rounded hover:bg-sub-600 hover:text-white"
                          >
                            <span className="material-icons text-lg">delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </section>
        <section className="flex justify-end mb-4">
          <Pagenation
            pageOffset={pagenation.pageOffset}
            pageSize={pagenation.pageSize}
            totalCount={pagenation.totalCount}
            onPageClick={handleClickPage}
          />
        </section>
      </div>
    </section>
  );
};
