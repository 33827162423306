export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const UPDATE_ACCOUNT = '/update-account';
export const NEW_PASSWORD = '/new-password';

export const ACCOUNT_SETTINGS = '/account/settings';
export const RESOURCES = '/account/resources';
export const PERSONAL_SERVER = '/account/resources/personal-server';
export const CONNECT_SERVER = '/account/resources/personal-server/connect';

export const NOTICES = '/admin/notices';
export const NEW_NOTICE = '/admin/notices/new';
export const UPDATE_NOTICE = '/admin/notices/:noticeId/update';
