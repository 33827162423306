import { PYNETSPRESSO_BANNER_LINK } from '../../../../constants';

export const PyNetsPressoBanner = () => {
  return (
    <section className="flex justify-center items-center bg-banner md:h-[60px] border-b border-notaBlue-700">
      <div className="text-white sm:text-base md:text-lg py-4">
        <div className="sm:block md:inline-block md:mr-2">Experience NetsPresso with a Python package.</div>
        <a className="text-yellow underline" href={PYNETSPRESSO_BANNER_LINK} target="_blank" rel="noreferrer">
          Try it here!
        </a>
      </div>
    </section>
  );
};
