import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY } from '../../../../../constants';
import { UserService } from '../../../../../library/apis';

export const MyPlan = () => {
  const { data } = useQuery([REACT_QUERY.USER], UserService.readUser);

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">My Plan</h1>
      <section className="bg-white rounded-lg shadow p-6 py-2 mb-10 text-sm">
        <ul>
          <li className="py-2 text-gray-900">
            <span className="text-sub-700 font-medium inline-block w-40">Plan</span>
            <span>Community (Results made with this plan can be made public to the community)</span>
          </li>
          <li className="py-2 text-gray-900">
            <span className="text-sub-700 font-medium inline-block w-40">Dataset Storage</span>
            <span>Unlimited</span>
          </li>
          <li className="py-2 text-gray-900">
            <span className="text-sub-700 font-medium inline-block w-40">Credits</span>
            <span className="text-sub">{data?.data.credit || '-'}</span>
            <span>/500</span>
          </li>
        </ul>
      </section>
    </>
  );
};
