import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogType, Heading, LabeledTextInput } from '@netspresso/components';
import NetsPressoLogo from '@netspresso/components/assets/logos/logo.svg';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Action } from '../../constants';
import { INVALID_EMAIL } from '../../constants/errors';
import { useModalContext } from '../../contexts';
import { UserService } from '../../library/apis/UserService';

const NewPasswordDefaultValues = {
  email: '',
};

// todo 외부파일이동
const NewPasswordSchema = z.object({
  email: z.string().email(INVALID_EMAIL),
});

type NewPasswordType = z.infer<typeof NewPasswordSchema>;

export const NewPassword = () => {
  const navigate = useNavigate();

  const [, dispatchModal] = useModalContext();

  const { control, handleSubmit, getValues } = useForm({
    resolver: zodResolver(NewPasswordSchema),
    defaultValues: NewPasswordDefaultValues,
  });

  const show404Error = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="New password failed"
          infoText="Your account does not exist."
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleNewPasswordSuccess = () => {
    const email = getValues('email');

    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="New password success"
          infoText={`An email has been sent to ${email}.`}
          width="w-[600px]"
          onClickConfirm={() => {
            navigate('/signin');
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleNewPasswordError = (_: AxiosError) => {
    show404Error();
  };

  const { mutate, isLoading } = useMutation(UserService.resetPassword, {
    onSuccess: handleNewPasswordSuccess,
    onError: handleNewPasswordError,
  });

  const handleClickNewPassword: SubmitHandler<NewPasswordType> = ({ email }) => {
    mutate({ email });
  };

  const handleInvalidInputs: SubmitErrorHandler<NewPasswordType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickLogo = () => {
    navigate('/');
  };

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div>
        <section onClick={handleClickLogo} className="flex justify-center hover:cursor-pointer">
          <NetsPressoLogo transform="scale(1.389)" />
        </section>
        <Heading className="text-2xl mt-2 text-gray-900 text-center">New password</Heading>
        <section className="bg-white py-8 px-8 shadow rounded-lg mt-6 w-[448px]">
          <form className="space-y-4" onSubmit={handleSubmit(handleClickNewPassword, handleInvalidInputs)}>
            <p className="text-m">
              Please enter the email address you signed up for.
              <br />
              We will send you an email to set new password.
            </p>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="email"
                    label="Email address"
                    type="email"
                    autoComplete="email"
                    required
                  />
                )}
              />
            </div>
            <div>
              <Button color="secondary" size="full" submit shadow disabled={isLoading}>
                Set new password
              </Button>
            </div>
            <div className="flex items-center">
              <div className="text-sm">
                <Link to="/signin" className="font-medium text-sub hover:text-sub-700">
                  &lt; Back to Sign in
                </Link>
              </div>
            </div>
          </form>
        </section>
        <p className="text-center text-sm text-gray-900 mt-4">
          Can&apos;t sign in to your account?{' '}
          <a href="mailto:netspresso@nota.ai" className="text-sub font-bold">
            netspresso@nota.ai
          </a>
        </p>
      </div>
    </div>
  );
};
