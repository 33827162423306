import { Spinner } from '@netspresso/components';

interface LoadingBoxProps {
  fullScreen?: boolean;
}

export const LoadingBox = ({ fullScreen }: LoadingBoxProps) => {
  if (fullScreen) {
    return (
      <div className="fixed left-0 top-0 right-0 bottom-0 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Spinner />
    </div>
  );
};
