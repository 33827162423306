import React from 'react';
import { CURRENT_STEPS } from '../../lib/enums';
import { CurrentSteps } from '../../lib/types';

type AgentContextType = [CurrentSteps, React.Dispatch<React.SetStateAction<CurrentSteps>>];

export const AgentStepContext = React.createContext<AgentContextType | null>(null);

export const AgentStepContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const stepsUseState = React.useState<CurrentSteps>(CURRENT_STEPS.CREATE_SERVER);

  return <AgentStepContext.Provider value={stepsUseState}>{children}</AgentStepContext.Provider>;
};

export const useAgentContext = (): AgentContextType => {
  const context = React.useContext(AgentStepContext);

  if (!context) {
    throw new Error('useAgentContext must be used within a AgentStepContextProvider');
  }

  return context;
};
