import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import 'prismjs/themes/prism-tomorrow.css';
import { NotionRenderer } from 'react-notion';
import 'react-notion/src/styles.css';

const getPageInfo = async (pageId: string) => {
  const { data } = await axios.get(`https://notion-api.splitbee.io/v1/page/${pageId}`);

  return data;
};

export const NotionViewer = ({ pageId }: { pageId: string }) => {
  const { data, isLoading } = useQuery(['Notion', pageId], () => getPageInfo(pageId));

  if (isLoading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <NotionRenderer blockMap={data} />
    </div>
  );
};
