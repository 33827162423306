import React, { InputHTMLAttributes } from 'react';

interface SelectBoxProps extends InputHTMLAttributes<HTMLSelectElement> {}

export const SelectBox = React.forwardRef<HTMLSelectElement, SelectBoxProps>(({ children, ...props }, ref) => {
  return (
    <select
      ref={ref}
      {...props}
      className="block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary w-full"
    >
      {children}
    </select>
  );
});
