import { useMemo } from 'react';

interface PagenationProps {
  pageOffset: number;
  pageSize: number;
  totalCount: number;
  onPageClick: (pageNumber: number) => void;
}

export function Pagenation({ pageOffset, pageSize, totalCount, onPageClick }: PagenationProps) {
  const offsetsLimit = 10;

  const maxOffset = useMemo(() => Math.ceil(totalCount / pageSize) - 1, [totalCount, pageSize]);

  const startOffset = useMemo(() => Math.floor(pageOffset / offsetsLimit) * offsetsLimit, [pageOffset, offsetsLimit]);

  const endOffset = useMemo(
    () => Math.min(Math.ceil(startOffset + offsetsLimit - 1), maxOffset),
    [maxOffset, startOffset]
  );

  const offsets = useMemo(
    () => [...Array(endOffset - startOffset + 1).keys()].map((pageNumber) => pageNumber + startOffset),
    [endOffset, startOffset]
  );

  const handleClickPage: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const offset = Number(e.currentTarget.value);

    onPageClick(offset);
  };

  return (
    <div className="flex justify-end">
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        {startOffset > 0 && (
          <button
            type="button"
            onClick={handleClickPage}
            value={startOffset - 1}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
        {offsets.map((o, index) => {
          if (o === Number(pageOffset)) {
            return (
              <button
                type="button"
                key={index}
                value={o}
                // onClick={handleClickPage}
                aria-current="page"
                className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {' '}
                {o + 1}{' '}
              </button>
            );
          }

          return (
            <button
              type="button"
              key={index}
              value={o}
              onClick={handleClickPage}
              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {' '}
              {o + 1}{' '}
            </button>
          );
        })}
        {endOffset < maxOffset && (
          <button
            type="button"
            value={endOffset + 1}
            onClick={handleClickPage}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </nav>
    </div>
  );
}
