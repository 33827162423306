import React from 'react';
import CheckMark from '../../../assets/icons/check-mark.svg';

interface Props {
  id: string;
  className?: string;
  disabled?: boolean;
  value?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

type Ref = React.Ref<HTMLInputElement>;

export const Checkbox = React.forwardRef(
  ({ id, onChange, onClick, value = false, disabled = false, className = '' }: Props, ref: Ref) => {
    return (
      <label className="relative block cursor-pointer" htmlFor={id}>
        <input
          id={id}
          className="absolute opacity-0 left-0 top-0 cursor-pointer"
          type="checkbox"
          disabled={disabled}
          checked={value}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
        />
        <span className={`inline-block w-4 h-4 p-0.5 border border-gray-900 rounded ${className}`}>
          <CheckMark />
        </span>
      </label>
    );
  }
);
