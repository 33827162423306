import React from 'react';

interface Props {
  id: string;
  options: Option[];
  className?: string;
  disabled?: boolean;
  value?: string | number;
  onChange?: () => void;
}

export type Option = {
  name: string;
  value: string;
};

type Ref = React.Ref<HTMLSelectElement>;

export const Select = React.forwardRef(({ id, options, className, value, disabled, onChange }: Props, ref: Ref) => {
  return (
    <select
      ref={ref}
      className={`block focus:outline-none focus:ring-secondary focus:border-secondary w-full shadow-sm text-sm border border-gray-300 rounded-md px-3 py-2 ${className}`}
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );
});
