import Cookies from 'js-cookie';
import { COOKIE } from '../../constants';

export const Token = (() => {
  const getAccessToken = () => {
    return Cookies.get(COOKIE.ACCESS_TOKEN) || '';
  };

  const getRefreshToken = () => {
    return Cookies.get(COOKIE.REFRESH_TOKEN) || '';
  };

  const setAccessToken = (token: string) => {
    Cookies.set(COOKIE.ACCESS_TOKEN, token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const setRefreshToken = (token: string) => {
    Cookies.set(COOKIE.REFRESH_TOKEN, token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const removeTokens = () => {
    Cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  return {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    removeTokens,
  };
})();
