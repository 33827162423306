import TagManager from 'react-gtm-module';
import { useAuthContext } from '../../contexts';

export const useGTM = () => {
  const { user } = useAuthContext();

  const setPageToDataLayer = (page: string = '') => {
    if (user) {
      TagManager.dataLayer({ dataLayer: { userId: user.user_id, page } });
    }
  };
  return { setPageToDataLayer };
};
