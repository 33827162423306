import { COMPRESSOR_BEST_PRACTICE_LINK } from '../../../../../../constants';

export const Compressor = () => {
  const handleClickDetails = () => {
    window.open(COMPRESSOR_BEST_PRACTICE_LINK);
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between lg:w-[894px] mx-auto pt-10 px-8 lg:px-0 lg:pt-18 mb-10">
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Automatic compression</h1>
          <ul className="list-disc text-m">
            <li>Only focus on the compression, not wasting time implementing complicated methods.</li>
            <li>Compress Pytorch, TensorFlow models immediately.</li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Structured pruning Filter decomposition</h1>
          <ul className="list-disc text-m">
            <li>
              Structured pruning directly improves the inference speed of AI models by reducing the amount of
              computation.
            </li>
            <li>Filter decomposition decomposes AI models and restores important information.</li>
            <li>Fine-tuning after compression is possible to restore the accuracy of the model.</li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">
            HW aware
            <br />
            model profiling
          </h1>
          <ul className="list-disc text-m">
            <li>Visualize the neural network to check the structure and available layers to be compressed.</li>
            <li>Profile the neural network on the target hardware to decide which layers to compress and how much.</li>
          </ul>
        </div>
      </div>
      <div className="lg:w-[894px] mx-auto font-title px-8 lg:px-0">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center bg-notaBlue-400 rounded-lg px-10 py-8">
          <div className="text-white mb-4 lg:mb-0">
            <h1 className="font-bold text-3xl">Best Practices</h1>
            <p className="text-xl">
              Inference speed improved up to <strong>40x</strong>
            </p>
          </div>
          <button
            type="button"
            onClick={handleClickDetails}
            className="bg-white text-sub py-3 px-10 rounded-lg font-semibold hover:text-white hover:bg-sub"
          >
            See details
          </button>
        </div>
      </div>
    </>
  );
};
