import { ENVIRONMENT } from './environment';

export enum FEATURE_FLAGS {
  SignUp = 'SignUp',
  Account = 'Account',
  ShowBanner = 'ShowBanner',
  CombinedCompression = 'CombinedCompression',
}

type FeatureFlags = {
  [key in FEATURE_FLAGS]: {
    [ENVIRONMENT.Production]: boolean;
    [ENVIRONMENT.Staging]: boolean;
    [ENVIRONMENT.Development]: boolean;
    [ENVIRONMENT.Local]: boolean;
    [ENVIRONMENT.LGCNS]: boolean;
  };
};

const defaultFlags: FeatureFlags = {
  SignUp: {
    prod: true,
    staging: true,
    dev: true,
    local: true,
    lgcns: false,
  },
  Account: {
    prod: true,
    staging: true,
    dev: true,
    local: true,
    lgcns: false,
  },
  ShowBanner: {
    prod: true,
    staging: true,
    dev: true,
    local: true,
    lgcns: false,
  },
  CombinedCompression: {
    prod: true,
    staging: true,
    dev: true,
    local: true,
    lgcns: false,
  },
};

export const flag = (featureFlag: FEATURE_FLAGS): boolean => {
  const processEnv = process.env.REACT_APP_ENVIRONMENT || process.env.ENVIRONMENT || process.env.NODE_ENV;

  let env: ENVIRONMENT;

  switch (processEnv) {
    case ENVIRONMENT.Production:
    case ENVIRONMENT.Staging:
    case ENVIRONMENT.Development:
    case ENVIRONMENT.Local:
    case ENVIRONMENT.LGCNS:
      env = processEnv;
      break;
    default:
      throw new Error(`Unexpected environment: ${processEnv}`);
  }

  const isEnabled = defaultFlags[featureFlag][env];

  return isEnabled;
};
