import { Spinner } from '@netspresso/components';
import { Action } from '../../../constants';
import { useModalContext } from '../../../contexts';

export const useLoader = () => {
  const [, dispatchModal] = useModalContext();

  const displayLoader = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <section className="fixed flex items-center justify-center h-[100px] w-[100px] rounded-lg shadow bg-white z-30">
          <Spinner />
        </section>
      ),
    });
  };

  const hideLoader = () => {
    dispatchModal({ type: Action.HIDE });
  };

  return { displayLoader, hideLoader };
};
