import Cookies from 'js-cookie';
import { Time } from './Time';

export const NoticeControl = (() => {
  const banForHours = (noticeId: string, banExpiryHour: number) => {
    const limit = new Date(new Date().getTime() + banExpiryHour * 60 * 60 * 1000);

    Cookies.set(noticeId, noticeId, { expires: limit });
  };

  const isBanned = (noticeId: string) => {
    const cookie = Cookies.get(noticeId);

    if (cookie) {
      return true;
    }

    return false;
  };

  const isValidTime = (startTime: string, expiryTime: string) => {
    const now = new Date();

    if (Time.UTC2Time(startTime) <= now && now < Time.UTC2Time(expiryTime)) {
      return true;
    }

    return false;
  };

  return {
    banForHours,
    isBanned,
    isValidTime,
  };
})();
