import { Outlet } from 'react-router';
import { Footer } from '../Footer';
import { NavBar } from '../NavBar';
import { NoticeBar } from '../NoticeBar';

export const Layout = () => {
  return (
    <div className="h-screen bg-subgray overflow-auto flex flex-col">
      <NoticeBar />
      <NavBar />
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
