import { useModalContext } from '../../../../../contexts';
import { useAgentContext } from '../../../../../contexts/AgentStepContext';
import { CURRENT_STEPS } from '../../../../../lib/enums';
import { SubNavigation } from '../../components';
import { ConnectPersonalServer } from '../ConnectPersonalServer';
import { CreatePersonalServer } from '../CreatePersonalServer';
import { FailedServer } from '../FailedServer';
import { SetPersonalServer } from '../SetPersonalServer';
import { Steps } from './components';

export const PersonalServer = () => {
  const [currentStep] = useAgentContext();
  const [modalContext] = useModalContext();

  return (
    <div className="relative overflow-hidden">
      <SubNavigation />
      <section className="w-full">
        <div className="relative max-w-7xl mx-auto py-6 pt-4 px-8">
          {!modalContext.showModal && <Steps />}
          {currentStep === CURRENT_STEPS.CREATE_SERVER && <CreatePersonalServer />}
          {currentStep === CURRENT_STEPS.SET_SERVER && <SetPersonalServer />}
          {currentStep === CURRENT_STEPS.CONNECT_SERVER && <ConnectPersonalServer />}
          {currentStep === CURRENT_STEPS.CONNECT_FAILED && <FailedServer />}
        </div>
      </section>
    </div>
  );
};
