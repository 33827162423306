import React, { ChangeEventHandler } from 'react';

type TextInputProps = {
  id: string;
  width: string;
  className?: string;
  error?: string;
  isDisable?: boolean;
  placeholder?: string;
  padding?: string;
  value?: string;
  type?: 'text' | 'number';
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const TextInput: React.FC<TextInputProps> = ({
  className,
  id,
  width,
  type = 'text',
  padding = 'px-4 py-1',
  error = '',
  placeholder = '',
  value = '',
  isDisable = false,
  onChange,
}) => {
  return (
    <div className={width}>
      <input
        id={id}
        name={id}
        className={`${
          error ? 'border-2 border-danger focus:border-danger' : 'border border-defaultGray focus:border-secondary'
        } rounded w-full ${padding} placeholder-defaultGray-300 focus:outline-none disabled:bg-lineGray disabled:text-defaultGray ${className}`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={isDisable}
      />
      <span className={`${error ? 'block' : 'hidden'} text-xs text-danger`}>{error}</span>
    </div>
  );
};
