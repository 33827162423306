import React, { ReactNode } from 'react';
import { BaseButton } from '../types';

export type ButtonColor = 'primary' | 'secondary' | 'secondary-point' | 'point' | 'danger';
// | 'warning' // not supported yet
// | 'info' // not supported yet
// | 'success' // not supported yet
// | 'gray' // not supported yet
// | 'black' // not supported yet

export type ButtonSize = 'sm' | 'full';
// xs,
// md,
// lg,

export type ButtonVariant = 'solid' | 'outline' | 'ghost';

interface Props extends BaseButton {
  children?: ReactNode;
  className?: string;
  color?: ButtonColor;
  size?: ButtonSize;
  variant?: ButtonVariant;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const buttonStyles = (color: ButtonColor, variant: ButtonVariant) => {
  switch (color) {
    case 'primary':
      return variant === 'solid'
        ? 'bg-main hover:bg-main-700 text-white'
        : variant === 'outline'
        ? 'border border-main bg-white text-main hover:bg-main-50'
        : 'bg-white text-main hover:bg-main-50';
    case 'secondary':
      return variant === 'solid'
        ? 'bg-sub hover:bg-sub-600 text-white'
        : variant === 'outline'
        ? 'border border-sub bg-white text-sub hover:bg-sub-50'
        : 'bg-white text-sub hover:bg-sub-50';
    case 'secondary-point':
      return variant === 'solid'
        ? 'bg-subpoint hover:bg-subpoint-700 text-white'
        : variant === 'outline'
        ? 'border border-subpoint bg-white text-subpoint hover:bg-subpoint-50'
        : 'bg-white text-subpoint hover:bg-subpoint-50';
    case 'point':
      return variant === 'solid'
        ? 'bg-point hover:bg-point-500 text-main'
        : variant === 'outline'
        ? 'border border-point bg-white text-point hover:bg-point-50'
        : 'bg-white text-point hover:bg-point-50';
    case 'danger':
      return variant === 'solid'
        ? 'bg-danger hover:bg-danger-active text-white'
        : variant === 'outline'
        ? 'border border-danger bg-white text-danger hover:bg-danger-50'
        : 'bg-white text-danger hover:bg-danger-50';
  }
};

const buttonDisabledStyle = (variant: ButtonVariant) => {
  return variant === 'solid'
    ? 'disabled:bg-disabledGray disabled:text-white'
    : variant === 'outline'
    ? 'disabled:border-disabledGray disabled:text-disabledGray'
    : 'disabled:text-disabledGray';
};

export const Button: React.FC<Props> = ({
  id,
  children,
  className,
  disabled,
  submit = false,
  shadow = false,
  size = 'sm',
  color = 'primary',
  variant = 'solid',
  onClick,
  ...rest
}) => (
  <button
    id={id}
    className={`flex justify-center rounded-md text-sm px-4 py-2 leading-[16px] cursor-pointer ${buttonStyles(
      color,
      variant
    )} ${buttonDisabledStyle(variant)} ${shadow ? 'shadow-sm' : ''} font-bold ${
      disabled ? 'pointer-events-none' : ''
    } ${size === 'full' ? 'w-full' : ''} ${className}`}
    disabled={disabled}
    onClick={onClick}
    type={submit ? 'submit' : 'button'}
    {...rest}
  >
    {children}
  </button>
);
