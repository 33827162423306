import { ClientWithAuth } from './Clients';

export type NoticeType = 'release' | 'maintenance' | 'promotion' | 'news' | 'etc';

export interface Notice {
  id: number;
  notice_id: string;
  notice_type: NoticeType;
  title: string;
  content: string;
  link_url: string;
  release_version: string;
  announce_start_time: string;
  announce_expiry_time: string;
  cookie_lifetime: number;
  is_deleted: 0 | 1;
  created_author: string;
  updated_author: string;
  created_time: string;
  updated_time: string;
}

export interface Notices {
  data: Notice[];
  page_offset: number;
  page_size: number;
  total_count: number;
  total_pages: number;
}

export interface CreateNoticeRequest {
  noticeType: NoticeType;
  title: string;
  content: string;
  linkUrl: string;
  releaseVersion: string;
  announceStartTime: number;
  announceExpiryTime: number;
  cookieLifeTime: number;
}

export interface ReadNoticesRequest {
  pageOffset: number;
  pageSize: number;
}

export interface ReadNoticeRequest {
  noticeId: string;
}

export interface UpdateNoticeRequest {
  noticeId: string;
  noticeType: NoticeType;
  title: string;
  content: string;
  linkUrl: string;
  releaseVersion: string;
  announceStartTime: number;
  announceExpiryTime: number;
  cookieLifeTime: number;
}

export interface DeleteNoticeRequest {
  noticeId: string;
}

export interface NoticeDeleteResponse {
  message: string;
}

export const NoticeService = (() => {
  const createNotice = ({
    noticeType,
    title,
    content,
    linkUrl,
    releaseVersion,
    announceStartTime,
    announceExpiryTime,
    cookieLifeTime,
  }: CreateNoticeRequest) => {
    const payload = {
      notice_type: noticeType,
      title,
      content,
      link_url: linkUrl,
      release_version: releaseVersion,
      announce_start_time: announceStartTime,
      announce_expiry_time: announceExpiryTime,
      cookie_lifetime: cookieLifeTime,
    };

    return ClientWithAuth.post<Notice>(`/notice`, payload);
  };

  const readNotices = ({ pageOffset, pageSize }: ReadNoticesRequest) => {
    return ClientWithAuth.get<Notices>(`/notice?page_offset=${pageOffset}&page_size=${pageSize}`);
  };

  const readNotice = ({ noticeId }: ReadNoticeRequest) => {
    return ClientWithAuth.get<Notice>(`/notice/${noticeId}`);
  };

  const readRecentNotice = () => {
    return ClientWithAuth.get<Notice>(`/notice/recent`);
  };

  const updateNotice = ({
    noticeId,
    noticeType,
    title,
    content,
    linkUrl,
    releaseVersion,
    announceStartTime,
    announceExpiryTime,
    cookieLifeTime,
  }: UpdateNoticeRequest) => {
    const payload = {
      notice_type: noticeType,
      title,
      content,
      link_url: linkUrl,
      release_version: releaseVersion,
      announce_start_time: announceStartTime,
      announce_expiry_time: announceExpiryTime,
      cookie_lifetime: cookieLifeTime,
    };

    return ClientWithAuth.patch<Notice>(`/notice/${noticeId}`, payload);
  };

  const deleteNotice = ({ noticeId }: DeleteNoticeRequest) => {
    return ClientWithAuth.delete<NoticeDeleteResponse>(`/notice/${noticeId}`);
  };

  return { createNotice, readNotices, readNotice, readRecentNotice, updateNotice, deleteNotice };
})();
