import { Alert } from '@netspresso/components';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY } from '../../../../../constants';
import { AgentService } from '../../../../../library/apis';

export const NetspressoServer = () => {
  const { data } = useQuery([REACT_QUERY.AGENT], AgentService.readAgent);

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">Training Servers - NetsPresso</h1>
      <section className="flex flex-row justify-between mb-4">
        <Alert color="info" title="Please contact netspresso@nota.ai to request more NetsPresso server." />
        {/* <button
          type="button"
          className="bg-sub hover:bg-sub-600 disabled:bg-disabledGray text-white text-sm leading-none px-2 pr-3 py-1 rounded shadow"
          disabled
        >
          <span className="material-icons mr-2 mt-0.5">add</span>
          <span className="inline-block font-medium leading-7 align-top">New Server</span>
        </button>
        <div className="flex flex-row pr-2">
          <div className="mr-2">
            <p className="text-xs">Last check time: 2022-04-30 12:34</p>
            <p className="text-xs">Server status can be checked once every 60 seconds.</p>
          </div>
          <button type="button">
            <span className="material-icons text-sub transform hover:-rotate-180 transition duration-500 ease-in-out">
              sync
            </span>
          </button>
        </div> */}
      </section>
      <section className="bg-white rounded-lg shadow mb-10">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="py-3 pl-6 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Name
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Status
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Number of uses
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="font-normal">
              <td className="py-4 pl-6 text-sm text-gray-900 w-64">
                <p className="text-gray-900">NetsPresso Server (free)</p>
              </td>
              {data?.data?.nota_server?.status === 'available' && (
                <td className="px-3 py-4 text-sm text-green-600 w-64">Available</td>
              )}
              {data?.data?.nota_server?.status === 'running' && (
                <td className="px-3 py-4 text-sm text-blue-600 w-64">Running</td>
              )}
              {data?.data?.nota_server?.status === 'not_available' && (
                <td className="px-3 py-4 text-sm text-red-600 w-64">Not Available</td>
              )}
              <td className="px-3 py-4 text-sm text-gray-900">
                <span className="text-sub">{data?.data?.nota_server?.remained_num}</span>
                <span> remaining</span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};
