import { SEARCHER_BENCHMARKS_LINK } from '../../../../../../constants';

export const Searcher = () => {
  const handleClickDetails = () => {
    window.open(SEARCHER_BENCHMARKS_LINK);
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between lg:w-[894px] mx-auto pt-10 px-8 lg:px-0 lg:pt-18 mb-10">
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">AutoML</h1>
          <ul className="list-disc text-m">
            <li>Automate the time-consuming and repetitive AI model development process.</li>
            <li>Only need to prepare the dataset to train or retrain AI models.</li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8 mb-4 lg:mb-0">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">Neural Architecture Search</h1>
          <ul className="list-disc text-m">
            <li>
              Neural Architecture Search(NAS) enables to find optimized AI models for your dataset and target hardware.
            </li>
            <li>Balance the model&apos;s performance objectives and requirements to make the desired model.</li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg lg:w-[282px] py-10 px-8">
          <h1 className="text-xl h-[52px] text-sub font-bold mb-6">
            HW aware
            <br />
            training
          </h1>
          <ul className="list-disc text-m">
            <li>Develop an AI model that meets the target performance on your target hardware.</li>
            <li>Check performance benchmarks on actual hardware already set up in NetsPresso.</li>
          </ul>
        </div>
      </div>
      <div className="lg:w-[894px] mx-auto font-title px-8 lg:px-0">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center bg-notaBlue-400 rounded-lg px-10 py-8">
          <div className="text-white mb-4 lg:mb-0">
            <h1 className="font-bold text-3xl">Benchmarks</h1>
            <p className="text-xl">Check out the state-of-the-art optimization</p>
          </div>
          <button
            onClick={handleClickDetails}
            className="bg-white text-sub py-3 px-10 rounded-lg font-semibold hover:text-white hover:bg-sub"
          >
            See details
          </button>
        </div>
      </div>
    </>
  );
};
