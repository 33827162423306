import React, { ReactNode } from 'react';

interface Props {
  id: string;
  label: string | ReactNode;
  value?: string;
  type?: string;
  className?: string;
  autoComplete?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type Ref = React.Ref<HTMLInputElement>;

export const LabeledTextInput = React.forwardRef(
  (
    {
      id,
      label,
      value,
      autoComplete,
      onChange,
      type = 'text',
      className = '',
      required = false,
      disabled = false,
    }: Props,
    ref: Ref
  ) => (
    <>
      <label className={`block text-sm font-medium text-gray-700 ${className}`} htmlFor={id}>
        {label}
      </label>
      <div className={`mt-1 ${className}`}>
        <input
          id={id}
          name={id}
          type={type}
          value={value}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary"
          autoComplete={autoComplete}
          required={required}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
        />
      </div>
    </>
  )
);
