import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import { COOKIE } from '../../constants';

type DataLayer = {
  user_id: string;
  page?: string;
};

export const GoogleTagManager = (() => {
  const initialize = () => {
    if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };

      TagManager.initialize(tagManagerArgs);
    }
  };

  const agree = () => {
    Cookies.set(COOKIE.COOKIE_AGREE, 'true', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const disagree = () => {
    Cookies.set(COOKIE.COOKIE_AGREE, 'false', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const isAgreed = () => {
    const isAgreedFromCookie = Cookies.get(COOKIE.COOKIE_AGREE);

    if (isAgreedFromCookie === 'true') {
      return true;
    }

    if (isAgreedFromCookie === 'false') {
      return false;
    }

    return undefined;
  };

  const dataLayer = (tagManagerArgs: { dataLayer: DataLayer }) => {
    TagManager.dataLayer(tagManagerArgs);
  };

  return {
    initialize,
    agree,
    disagree,
    dataLayer,
    isAgreed,
  };
})();
