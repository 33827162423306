import { Button, Checkbox, Dialog, DialogType, Heading, LabeledTextInput, Select } from '@netspresso/components';
import NetsPressoLogo from '@netspresso/components/assets/logos/logo.svg';
import { Navigate, useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { Action, REGION_OPTIONS } from '../../constants';
import {
  AGE_AGREE,
  ALPHABET_NUMBER_ONLY_COMPANY,
  ALPHABET_ONLY_FIRST_NAME,
  ALPHABET_ONLY_LAST_NAME,
  INVALID_EMAIL,
  PERSONAL_AGREE,
  PRIVACY_AGREE,
  TERMS_AGREE,
  TOO_LONG_COMPANY,
  TOO_LONG_FIRST_NAME,
  TOO_LONG_LAST_NAME,
  TOO_SHORT_COMPANY,
  TOO_SHORT_FIRST_NAME,
  TOO_SHORT_LAST_NAME,
} from '../../constants/errors';
import { useAuthContext, useModalContext } from '../../contexts';
import { useGTM } from '../../hooks';
import { UserService } from '../../library/apis/UserService';
import { PersonalAgree, PrivacyAgree, TermsAgree } from '../Signup/Agree';

const UpdateAccountDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  currentPassword: '',
  region: 'us',
  ageAgree: false,
  personalAgree: false,
  privacyAgree: false,
  termsAgree: false,
  marketingAgree: false,
};

// todo 외부파일이동
const UpdateAccountSchema = z.object({
  email: z.string().email(INVALID_EMAIL),
  firstName: z
    .string()
    .min(2, TOO_SHORT_FIRST_NAME)
    .max(40, TOO_LONG_FIRST_NAME)
    .regex(/^[a-zA-Z]/, ALPHABET_ONLY_FIRST_NAME),
  lastName: z
    .string()
    .min(2, TOO_SHORT_LAST_NAME)
    .max(40, TOO_LONG_LAST_NAME)
    .regex(/^[a-zA-Z]/, ALPHABET_ONLY_LAST_NAME),
  company: z
    .string()
    .min(2, TOO_SHORT_COMPANY)
    .max(40, TOO_LONG_COMPANY)
    .regex(/^[0-9a-zA-Z]/, ALPHABET_NUMBER_ONLY_COMPANY)
    .or(z.literal('')),
  currentPassword: z.string(),
  region: z.string(),
  ageAgree: z.boolean().refine((value) => value, AGE_AGREE),
  personalAgree: z.boolean().refine((value) => value, PERSONAL_AGREE),
  privacyAgree: z.boolean().refine((value) => value, PRIVACY_AGREE),
  termsAgree: z.boolean().refine((value) => value, TERMS_AGREE),
  marketingAgree: z.boolean(),
});

type UpdateAccountType = z.infer<typeof UpdateAccountSchema>;

export const UpdateAccount = () => {
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const { user } = useAuthContext();

  const [, dispatchModal] = useModalContext();

  const [open, setOpen] = useState({
    personalAgree: false,
    privacyAgree: false,
    termsAgree: false,
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: zodResolver(UpdateAccountSchema),
    defaultValues: UpdateAccountDefaultValues,
  });

  const { mutate, isLoading } = useMutation(UserService.updateUser);

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setPageToDataLayer('Update your account');
    }
  }, [user, setValue]);

  const handleUpdateAccountSuccess = () => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Update account success"
          infoText="Your account has beed updated."
          width="w-[600px]"
          onClickConfirm={() => {
            navigate('/');
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });
  };

  const handleClickUpdateAccount: SubmitHandler<UpdateAccountType> = ({
    firstName,
    lastName,
    company,
    currentPassword,
    region,
    marketingAgree,
  }) => {
    mutate(
      {
        firstName,
        lastName,
        company,
        currentPassword,
        region,
        marketingAgree,
      },
      {
        onSuccess: handleUpdateAccountSuccess,
      }
    );
  };

  const handleInvalidInputs: SubmitErrorHandler<UpdateAccountType> = (error) => {
    Object.entries(error).every(([, { message }]) => {
      dispatchModal({
        type: Action.SHOW,
        payload: (
          <Dialog
            type={DialogType.alert}
            title="Input error"
            infoText={message as string}
            width="w-[600px]"
            onClickConfirm={() => {
              dispatchModal({ type: Action.HIDE });
            }}
          />
        ),
      });

      return false;
    });
  };

  const handleClickAgree: React.MouseEventHandler<HTMLInputElement> = (e) => {
    const { id } = e.currentTarget;

    switch (id) {
      case 'personal-agree':
        setOpen({ ...open, personalAgree: true });
        break;
      case 'privacy-agree':
        setOpen({ ...open, privacyAgree: true });
        break;
      case 'terms-agree':
        setOpen({ ...open, termsAgree: true });
        break;
    }
  };

  const handleClickModalConfirm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const modalName = e.currentTarget.value;

    switch (modalName) {
      case 'personalAgree':
        setValue('personalAgree', true);
        break;
      case 'privacyAgree':
        setValue('privacyAgree', true);
        break;
      case 'termsAgree':
        setValue('termsAgree', true);
        break;
    }
    setOpen({ ...open, [modalName]: false });
  };

  const handleClickModalCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const modalName = e.currentTarget.value;

    switch (modalName) {
      case 'personalAgree':
        setValue('personalAgree', false);
        break;
      case 'privacyAgree':
        setValue('privacyAgree', false);
        break;
      case 'termsAgree':
        setValue('termsAgree', false);
        break;
    }
    setOpen({ ...open, [modalName]: false });
  };

  if (user?.detail_data.privacy_policy_agreement) {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          type={DialogType.alert}
          title="Input error"
          infoText="You already updated your account."
          width="w-[600px]"
          onClickConfirm={() => {
            dispatchModal({ type: Action.HIDE });
          }}
        />
      ),
    });

    return <Navigate to="/" />;
  }

  const handleClickLogo = () => {
    navigate('/');
  };

  return (
    <div className="w-screen flex items-center justify-center pb-16">
      <div className="mt-16">
        <section onClick={handleClickLogo} className="flex justify-center hover:cursor-pointer">
          <NetsPressoLogo transform="scale(1.389)" />
        </section>
        <Heading className="text-2xl mt-2 text-gray-900 text-center">Update your account</Heading>
        <section className="bg-white py-8 px-8 shadow rounded-lg mt-6 w-[448px]">
          <form className="space-y-4" onSubmit={handleSubmit(handleClickUpdateAccount, handleInvalidInputs)}>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="email"
                    label="Email address *"
                    type="email"
                    autoComplete="email"
                    required
                    disabled
                  />
                )}
              />
            </div>
            <div className="flex flex-row">
              <div className="w-1/2">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <LabeledTextInput {...field} id="firstName" className="pr-2" label="First name *" required />
                  )}
                />
              </div>
              <div className="w-1/2">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <LabeledTextInput {...field} id="lastName" className="pr-2" label="Last name *" required />
                  )}
                />
              </div>
            </div>
            <div>
              <Controller
                name="company"
                control={control}
                render={({ field }) => <LabeledTextInput {...field} id="company" label="Company" />}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="region">
                Access region *
              </label>
              <Controller
                name="region"
                control={control}
                render={({ field }) => <Select {...field} id="region" options={REGION_OPTIONS} />}
              />
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="ageAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="age-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="age-agree">
                <b>I am 14 years or older to access this service *</b>
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="personalAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="personal-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="personal-agree">
                I accept the <b>Personal Information Agreement *</b>
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="privacyAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="privacy-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="privacy-agree">
                I accept the <b>Privacy Policy *</b>
              </label>
            </div>
            <div className="flex flex-row items-center">
              <Controller
                name="termsAgree"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} onClick={handleClickAgree} id="terms-agree" className="mt-1" />
                )}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="terms-agree">
                I accept the <b>Terms of Service *</b>
              </label>
            </div>
            <div className="flex flex-row">
              <Controller
                name="marketingAgree"
                control={control}
                render={({ field }) => <Checkbox {...field} onClick={handleClickAgree} id="marketing-agree" />}
              />
              <label className="ml-2 -mt-0.5 text-sm text-regular text-gray-900" htmlFor="marketing-agree">
                I accept to receive communications or be contacted by Nota about related technology
              </label>
            </div>
            <div>
              <Controller
                name="currentPassword"
                control={control}
                render={({ field }) => (
                  <LabeledTextInput
                    {...field}
                    id="current-password"
                    type="password"
                    label="Current password *"
                    autoComplete="current-password"
                    required
                  />
                )}
              />
            </div>
            <div>
              <Button size="full" color="secondary" submit shadow disabled={isLoading}>
                Update
              </Button>
            </div>
          </form>
        </section>
        <p className="text-center text-sm text-gray-900 mt-4">
          Can&apos;t sign in to your account?{' '}
          <a href="mailto:netspresso@nota.ai" className="text-subpoint font-bold">
            netspresso@nota.ai
          </a>
        </p>
      </div>
      {open.personalAgree && (
        <PersonalAgree
          handleClickModalConfirm={handleClickModalConfirm}
          handleClickModalCancel={handleClickModalCancel}
        />
      )}
      {open.privacyAgree && (
        <PrivacyAgree
          handleClickModalConfirm={handleClickModalConfirm}
          handleClickModalCancel={handleClickModalCancel}
        />
      )}
      {open.termsAgree && (
        <TermsAgree handleClickModalConfirm={handleClickModalConfirm} handleClickModalCancel={handleClickModalCancel} />
      )}
    </div>
  );
};
