import { useNavigate } from 'react-router';

import { Dialog } from '@netspresso/components';
import { parseDate, sortByCreatedTime } from '@netspresso/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Action, PERSONAL_SERVER, REACT_QUERY, VALIDATION_GUIDE_LINK } from '../../../../../constants';
import { useModalContext } from '../../../../../contexts';
import { useAgentContext } from '../../../../../contexts/AgentStepContext';
import { UserServer } from '../../../../../lib/agent';
import { CURRENT_STEPS } from '../../../../../lib/enums';
import { Agent, AgentService } from '../../../../../library/apis';
import { useLoader } from '../../../../../library/hooks';
import { parseServerColor, parseServerStatus } from '../../../../../library/utils';

export const PersonalServers = () => {
  const navigate = useNavigate();
  const [, setCurrentStep] = useAgentContext();
  const [, dispatchModal] = useModalContext();
  const { displayLoader, hideLoader } = useLoader();
  const [userServers, setUserServers] = React.useState<UserServer[]>([]);

  const onSuccessAgents = async (response: AxiosResponse<Agent>) => {
    hideLoader();
    const userServer = response.data.user_server;

    setUserServers(sortByCreatedTime(userServer).filter(isNotDeletedServer));
  };

  const { refetch } = useQuery([REACT_QUERY.AGENT], AgentService.readAgent, { onSuccess: onSuccessAgents });

  const deleteUserAgent = (agentId: string) => {
    hideLoader();
    displayLoader();

    return AgentService.deleteAgent(agentId);
  };

  const onSuccessDelete = () => {
    refetch();
  };

  const onErrorDelete = (data: AxiosError) => {
    // eslint-disable-next-line no-console
    console.error(data);

    hideLoader();
  };

  const deleteServer = useMutation({
    mutationFn: deleteUserAgent,
    onSuccess: onSuccessDelete,
    onError: onErrorDelete,
  });

  const parseLastActivated = (server: UserServer) => {
    if (server.last_activated_time) {
      return parseDate(server.last_activated_time);
    }

    return 'N/A';
  };

  const isNotDeletedServer = (server: UserServer) => {
    return server.status !== 'deleted';
  };

  const onClickNewServer: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    setCurrentStep(CURRENT_STEPS.CREATE_SERVER);
    navigate(PERSONAL_SERVER);
  };

  const onClickConnect = (agentId: string) => {
    displayLoader();

    setCurrentStep(CURRENT_STEPS.CONNECT_SERVER);
    navigate(`${PERSONAL_SERVER}/${agentId}`);
  };

  const onClickConfirm = async (agentId: string) => {
    await deleteServer.mutateAsync(agentId);
  };

  const onClickCancel = () => {
    hideLoader();
  };

  const onClickDelete = (agentId: string) => {
    dispatchModal({
      type: Action.SHOW,
      payload: (
        <Dialog
          title="Are you sure you want to delete?"
          infoText="The selected server will be deleted."
          onClickConfirm={() => onClickConfirm(agentId)}
          onClickCancel={onClickCancel}
        />
      ),
    });
  };

  return (
    <>
      <h1 className="text-main text-xl font-title font-bold mb-4 pl-3">Training Servers - Personal</h1>
      <section className="flex flex-row justify-between mb-4">
        <button
          type="button"
          className="bg-sub hover:bg-sub-600 disabled:bg-disabledGray text-white text-sm leading-none px-2 pr-3 py-1 rounded shadow"
          onClick={onClickNewServer}
        >
          <span className="material-icons mr-2 mt-0.5">add</span>
          <span className="inline-block font-medium leading-7 align-top">New Server </span>
        </button>
        <a className="text-sm text-blue-600 self-end" href={VALIDATION_GUIDE_LINK} target="_blank" rel="noreferrer">
          &rsaquo; Validation guide for Local Dataset
        </a>
      </section>
      <section className="bg-white rounded-lg shadow mb-10">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="py-3 pl-6 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Name
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Status
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-700" scope="col">
                Last activated time
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {userServers.length > 0 ? (
              userServers.map((server) => {
                return (
                  <tr className="font-normal" key={server.agent_id}>
                    <td className="py-4 pl-6 text-sm text-gray-900 w-60">
                      <p className="text-gray-900">{server.name}</p>
                      <p className="text-gray-500">Created: {parseDate(server.created_time)}</p>
                    </td>
                    <td className={`px-3 py-4 text-sm w-64 ${parseServerColor(server.status)}`}>
                      {parseServerStatus(server.status)}
                    </td>
                    {server.status === 'not_available' ? (
                      <td className="pl-3 pr-4 py-4 text-sm text-gray-900">
                        <div className="flex justify-between">
                          <span className="leading-10">This server is not connected</span>
                          <div>
                            <button
                              type="button"
                              className="text-sm text-gray-900 hover:text-sub border border-gray-700 hover:border-sub px-4 py-3 rounded leading-[14px] mr-4"
                              onClick={() => onClickDelete(server.agent_id)}
                            >
                              Delete
                            </button>
                            <button
                              type="button"
                              className="text-sm text-white bg-sub hover:bg-sub-600 px-4 py-3 rounded leading-[14px] disabled:bg-gray-400"
                              onClick={() => onClickConnect(server.agent_id)}
                            >
                              Connect
                            </button>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td className="px-3 py-4 text-sm text-gray-900">{parseLastActivated(server)}</td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr className="font-normal">
                <td className="py-4 pl-6 text-sm text-gray-900" colSpan={3}>
                  There is no registered personal server.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </>
  );
};
