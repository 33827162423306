import { FEATURE_FLAGS, flag } from '@netspresso/shared';

export const StartPanel = () => {
  return (
    <div
      className="origin-top-left absolute top-6 left-0 mt-2 w-80 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <div className="group relative px-4 pt-6 pb-3">
        <a href={`${process.env.REACT_APP_SEARCHER_URL}/models`}>
          <dt>
            <div className="absolute">
              <span className="material-icons-outlined text-sub">saved_search</span>
            </div>
            <p className="ml-8 text-base font-medium text-gray-900 group-hover:text-sub">Model Searcher</p>
          </dt>
          <dd className="mt-1 ml-8 text-m text-gray-500 group-hover:text-sub">
            Automatically search optimized models for your target device
          </dd>
        </a>
      </div>
      <div className="group relative px-4 py-3">
        <a
          href={
            flag(FEATURE_FLAGS.CombinedCompression)
              ? `${process.env.REACT_APP_SEARCHER_URL}/models`
              : `${process.env.REACT_APP_COMPRESSOR_URL}/models`
          }
        >
          <dt>
            <div className="absolute">
              <span className="material-icons-outlined text-sub">compress</span>
            </div>
            <p className="ml-8 text-base font-medium text-gray-900 group-hover:text-sub">Model Compressor</p>
          </dt>
          <dd className="mt-1 ml-8 text-m text-gray-500 group-hover:text-sub">
            Make your model lighter and faster by compression techinique
          </dd>
        </a>
      </div>
      <div className="group relative px-4 pt-3 pb-6">
        <a href={`${process.env.REACT_APP_SEARCHER_URL}/models`}>
          <dt>
            <div className="absolute">
              <span className="material-icons text-sub">rocket_launch</span>
            </div>
            <p className="ml-8 text-base font-medium text-gray-900 group-hover:text-sub">Model Launcher</p>
          </dt>
          <dd className="mt-1 ml-8 text-m text-gray-500 group-hover:text-sub">
            Accelerate and package for the deployment
          </dd>
        </a>
      </div>
    </div>
  );
};
